import { Button, PageHeader } from 'antd'
import { CustomersTable } from 'components/customers/CustomersTable'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Customers: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <PageHeader
        title="Clientes"
        extra={
          <Button onClick={() => navigate('/clientes/novo')}>NOVO CLIENTE</Button>
        }
      />

      <CustomersTable/>
    </>
  )
}

export default Customers