import React from 'react'
import Login from 'components/login/Login'
import { useAuth } from 'hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'

function LoginPage() {
  const auth = useAuth()
  const location = useLocation()

  let from = location.state?.from?.pathname || '/clientes'

  if (auth.user) {
    return <Navigate to={from} replace/>
  }

  return (
    <Login />
  )
}

export default LoginPage