import { PageHeader } from 'antd'
import { SellerForm } from 'components/sellers/SellerForm'
import { SellersProvider } from 'contexts/sellers'
import React, { useState } from 'react'

export const SellerCreate = ({ type }: { type: 'create' | 'edit' }) => {
  return (
    <>
      <PageHeader 
        title={ type === 'create' ? 'Novo vendedor' : 'Editar vendedor' }
      />
        <SellerForm type={type}/>
    </>
  )
}
