import { useState, useEffect } from 'react'

interface WindowDimensionProps {
  innerHeight: number
  innerWidth: number
}

function getWindowDimension(): WindowDimensionProps {
  const { innerWidth, innerHeight } = window
  return {
    innerHeight, innerWidth
  }
} 

export default function useWindowDimension() {
  const [windowDimension, setWindowDimension] = useState(getWindowDimension())

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimension())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimension
}