import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'


interface TableProps {
  columns: ColumnsType<any>
  dataSource?: object[]
  props?: any
}

export const dataSourceMock: object[] = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
    email: 'mike@mike',
    shopping: 'Mike`s'
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
    email: 'mike@mike',
    shopping: 'Mike`s'
  },
  {
    key: '3',
    name: 'Niki',
    age: 22,
    address: '1w0 Downing Street',
    email: 'mike@mike',
    shopping: 'Mike`s'
  },
  {
    key: '24',
    name: 'Lee John',
    age: 40,
    address: '310 Doowning Street',
    email: 'mike@mike',
    shopping: 'Mike`s'
  },
];

export const TableComponent: React.FC<TableProps> = ({ dataSource, columns, props }) => {

  return (
    <>
      <Table 
        {...props}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 500 }}
      />
    </>
  )
}
