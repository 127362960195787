import { PageHeader } from 'antd'
import { CustomerForms } from 'components/customers/CustomerForms'
import React from 'react'

export const CustomerCreate = ({ type }: { type: 'create' | 'edit' }) => {
  return (
    <>
      <PageHeader 
        title={type === 'create' ? 'Novo cliente': 'Editar cliente' }
      />
        <CustomerForms type={type}/>
    </>
  )
}
