import React, { Children } from 'react'
import { RouteObject } from 'react-router-dom'
import RequireAuth from '../components/auth/RequireAuth'
import Login from 'pages/Login'
import Layout from 'components/layouts/Layout'
import ProtectedRoute from 'components/auth/ProtectedRoute'
import Sellers from 'pages/seller/Sellers'
import { SellerRoot } from 'pages/seller'
import Customers from 'pages/customer/Customer'
import { SellerCreate } from 'pages/seller/SellerCreate'
import { CustomerRoot } from 'pages/customer'
import { CustomerCreate } from 'pages/customer/CustomerCreate'

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/',
    element: <RequireAuth>
     <Layout />
    </RequireAuth>,
    children: [
      {
        path: 'clientes',
        element: <CustomerRoot />,
        children: [
          {
            path: '',
            element: <Customers />
          },
          {
            path: 'novo',
            element: <CustomerCreate type='create'/>
          },
          {
            path: ':id',
            element: <CustomerCreate type='edit'/>
          }
        ]
      },
      {
        path: 'vendedores',
        element:  <ProtectedRoute>
            <SellerRoot />
        </ProtectedRoute>,
        
        children: [
          {
            path: '',
            element: <Sellers />
          },
          {
            path: 'novo',
            element: <SellerCreate type='create'/>
          },
          {
            path: ':id',
            element: <SellerCreate type='edit'/>
          }
        ]
      },
    ]
  }
]