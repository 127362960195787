import { Button, PageHeader } from 'antd'
import { DataSourceProps } from 'components/customers/CustomersTable'
import { SellersTable } from 'components/sellers/SellersTable'
import { dataSourceMock } from 'components/Table'
import { SellersContext, SellersProvider } from 'contexts/sellers'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { api, http } from 'services'

const Sellers: React.FC = () => {
  // const [data, setData] = useState<DataSourceProps[]>([])
  // const sellerContext = useContext(SellersContext)
  const navigate = useNavigate()

  // useEffect(() => {
  //   async function getSellers() {
  //     // const response = await api({
  //     //   method: 'get',
  //     //   url: '/members',
  //     // })
  //     const response = await http.get('/members')
  //       .then(response => 
  //         Array.from(response.data, 
  //         (data: { props: DataSourceProps, _id: string})  =>  { return { ...data.props, id: data._id } })
  //       )

  //     // setData(response as DataSourceProps[])
  //   }

  //   getSellers()
  // }, [])

  return (
    <>
      <PageHeader
        title="Vendedores"
        extra={
          <Button onClick={() => navigate('novo')}>NOVO VENDEDOR</Button>
        }
      />
        <SellersTable />
    </>
  )
}

export default Sellers