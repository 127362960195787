import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'

import './style.scss'
import LogoGuaraves from 'assets/images/logo-guaraves.svg'
// import { GoogleOutlined } from '@ant-design/icons'
import useWindowDimension from 'hooks/useWindowDimensions';
import { useEffect } from 'react';
import { LoaderSpin } from 'components/Loader';
import { api } from 'services';

interface GoogleDecodedProps {
  email: string
  nome: string
  exp: number
  picture: string
  sub: string
}

function Login() {
  const auth = useAuth();
  const { innerWidth } = useWindowDimension()
  const [buttonSize, setButtonSize] = useState('0')
  const [msgError, setMsgError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const handleLogin = useCallback(async (credentialResponse: CredentialResponse) => {
    setLoading(true)
    try {
      const response = await api({
        method: 'post',
        url: '/auth',
        data: { id_token: credentialResponse.credential }
      })

      if (response.status === 201) {
        let data = jwtDecode<GoogleDecodedProps>(credentialResponse.credential as string)
        let { access_token, refresh_token } = response.data
        auth.Login({ ...data, ...credentialResponse, access_token, refresh_token })
      } else {
        setMsgError('Falha ao tentar logar com este e-mail! Tente outro e-mail ou entre em contato com o suporte (suporte@guaraves.com.br).')

      }
    } catch {
      setMsgError('Falha ao tentar logar com este e-mail! Tente outro e-mail ou entre em contato com o suporte (suporte@guaraves.com.br).')
    } finally {
      setLoading(false)
    }
  }, [auth])

  useEffect(() => {
    if (innerWidth > 700) {
      setButtonSize('340px')
    } else {
      setButtonSize('100%')
    }
  }, [innerWidth])


  return (
    <div id='login-component'>
      <div className="card">
        <div className="logo">
          <img src={LogoGuaraves} alt="Guaraves" />
        </div>
        <div className="info">
          <h3>Portal do Vendedor</h3>
          <h2>Olá, Visitante!</h2>
          {msgError && <p>{msgError} <span onClick={() => setMsgError(null)}>{" "}x</span> </p>}
        </div>
            <div className="button-login">
              {!loading ? 
                <GoogleLogin 
                  onSuccess={credentialResponse => {
                    handleLogin(credentialResponse)
                  }}
                  onError={() => {
                    setMsgError('Falha ao tentar logar com este e-mail! Tente outro e-mail ou entre em contato com o suporte (suporte@guaraves.com.br).')
                  }}
                  size='large'
                  width={buttonSize}
                  theme='filled_blue'
                  logo_alignment='left'
                />
                :
                <LoaderSpin/>
              }
            </div> 
      </div>
    </div>
  )
}

export default Login