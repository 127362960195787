import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

interface ProtectedRouteProps {
  children: JSX.Element
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  let auth = useAuth()
  let location = useLocation()

  if (!auth.user?.admin) {
    return <Navigate to="/clientes" state={{ from: location }} replace />
  }

  return children
}

export default ProtectedRoute