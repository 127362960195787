import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Space, Tag } from 'antd'
import { TablePaginationConfig } from 'antd/es/table/interface'
import { ColumnsType } from 'antd/lib/table'
import { LoaderPage } from 'components/Loader'
import { TableComponent } from 'components/Table'
import { CustomersContext } from 'contexts/customer'
import { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

export type PartnerDTO = {
  props?: Object,
  name?: string,
  rg?: string,
  cpf?: string,
  public_place?: string,
  number?: string,
  uf?: string,
  city?: string,
  neighborhood?: string,
  cep?: string,
  customer_id?: string,
  role?: string,
  rg_issuer?: string
  id?: string
  _id?: string
}

export type DeliveriesDTO = {
  accepts_disposable_pallets?: boolean,
  accepts_pbr_pallets?: boolean,
  cep?: string,
  city?: string,
  local_name?: string,
  max_vehicles_by_days?: number,
  neighborhood?: string
  public_place?: string,
  receives_on_saturday?: boolean,
  restriction_cart?: boolean,
  restriction_truck?: boolean,
  uf?: string
  uses_pallet?: boolean,
  uses_scheduling?: boolean,
  weekdays_do_not_delivery?: string[],
  _id?: string,
  id?: string,
  props?: Object,
}

export type SocialContractsDTO = {
  activity_start?: Dayjs,
  cnpj_previous_company?: string,
  integralized?: string,
  participations_in_previous?: number,
  previous_company?: string,
  previous_cep?: string,
  previous_company_local_reg_number?: string,
  id?: string,
  is_sucessor?: boolean,
  props?: Object,
  _id?: string,
}

export interface DocumentProps {
  props: {
    customer_id: string
    document_type: 'CNH' | 'RG' | 'CNPJ_CARD' | 'SOCIAL_CONTRACT' | 'BILL' | 'CUSTOMER_TERM'
    key: string
    url: string
    public_url: string
  }
  _id: string
}

export interface DataSourceProps {
  key: string
  id?: string
  age?: number
  accepts_pbr_pallets?: boolean
  accepts_disposable_pallets?: boolean
  activity_start?: Dayjs 
  email: string | Array<string>
  shopping?: string
  customer_type: "CHICKEN" | "RATIONS" | "SIMPLIFIED",
  member_id?: string,
  bigdata?: string,
  branch?: string,
  capital?: number,
  cep?: string,
  checkouts_quantity?: string,
  city?: string,
  cnae?: string,
  cnae_code?: string,
  cnpj?: string,
  cpf?: string
  credit_limit_sugestion?: string
  credit_sector?: string
  credit_zone?: string
  company_name?: string,
  company_opening_date?: Dayjs,
  complement?: string,
  credit_payment?: "PREPAYMENT" | "BILLET" | "ACCOUNT_CREDIT" | "CARD" | "PIX",
  credit_suggestion?: number,
  bank_supplier_institutions?: string
  bank_supplier_phone?: string,
  bank_supplier_agency?: string,
  bank_supplier_checking_account?: string
  buy_sugestions?: string[]
  belongsNetwork?: boolean
  delivery?: DeliveriesDTO
  documents?: DocumentProps[]
  fantasy_name?: string,
  google_phone?: string,
  has_cold_camara?: false,
  has_cold_tools?: false,
  latitude?: string,
  legal_nature?: string,
  legal_nature_code?: string,
  longitude?: string,
  local_name?: string
  neighborhood?: string,
  number?: string,
  phone?: string | Array<string>
  phone_classification?: string,
  phone_contact?: string,
  public_place?: string,
  purchase_suggestion?: string,
  rank?: string,
  registration_status?: string,
  registration_status_date?: Dayjs,
  related_company?: string,
  sales_channel?: string,
  search_count?: string,
  enterprise_address?: string 
  enterprise_cnpj?: string
  enterprise_successor?: boolean
  eo?: string
  max_vehicles_by_days?: number
  palletized_delivery_logistics?: string[]
  // partner_street?: string
  // partner_cep?: string
  // partner_city?: string
  // partner_compliment?: string
  // partner_name?: string
  // partner_neighbhood?: string
  // partner_number?: number
  // partner_uf?: string
  // partner_role?: string
  // partner_rg_issuer?: string
  // partner_cpf?: string
  // partner_rg?: string
  partner?: PartnerDTO
  phone_client?: string
  registry_capital?: string
  rg?: string
  receives_on_saturday?: boolean
  restriction_cart?: boolean
  restriction_truck?: boolean
  operation?: string
  name_enterprise_successor?: string
  form_of_payment?: string[]
  integralized?: number
  is_store?: string[]
  sector?: string
  size?: string
  supplier_address?: string
  supplier_city?: string
  supplier_name?: string
  supplier_phone?: string | Array<string>
  saturday_receive_logistics?: boolean
  scheduling_logistics?: boolean
  social_contract?: SocialContractsDTO
  // social_participation?: string
  // social_registry?: string
  // social_resgistry_number?: string
  // store_structure?: string[]
  supplier_complement?: string
  supplier_neighborhood?: string
  supplier_number?: string
  supplier_uf?: string
  tags?: string,
  total_amount?: number
  uses_pallet?: boolean
  uses_scheduling?: boolean
  uf?: string
  wallet?: string
  weekdays_do_not_delivery?: string[]
  zone?: string
  network_id?: string
  network_name?: string
  vehicle_restriction_logistics?: string[]
  status?: boolean
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

export const CustomersTable: React.FC = () => {
  const customerContext = useContext(CustomersContext)
  const [customerData, setCustomerData] = useState<DataSourceProps[]>([])
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    }
  })
  const [loading, setLoading] = useState(true)
  const registrationStatusDictionary = {
    1: 'Ativa',
    2: 'Ativa Exterior',
    3: 'Baixada',
    4: 'Baixada Exterior',
    5: 'Suspensa',
    6: 'Suspensa Exterior',
    7: 'Nula',
    8: 'Nula Exterior',
    9: 'Inapta',
    10: 'Inapta Exterior'
  }

  const columns: ColumnsType<DataSourceProps> = [
    {
      dataIndex: 'company_name',
      key: 'company_name',
      title: 'Nome',
      width: 200,
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'E-mail',
      width: 200,
    },
    {
      dataIndex: 'phone',
      key: 'phone',
      title: 'Telefone'
    },
    {
      dataIndex: 'registration_status',
      key: 'registration_status',
      title: 'Situação cadastral',
      render: (_, record) => (
        <>
          {
            record.registration_status ? 
            <Tag>{
              registrationStatusDictionary[
                Number(record.registration_status) as 
                keyof 
                typeof 
                registrationStatusDictionary
              ]}
            </Tag> :
            <Tag>Sem status</Tag>
          }
        </>
      )
    },
    {
      key: 'action',
      title: 'Ações',
      render: (_, record) => (
        <Space>
          <Link to={`/clientes/${record.id}`}> <EyeOutlined style={{ fontSize: '20px'}}/> </Link>
          <Button 
            onClick={() => handleCustomerDelete(record)}
            type="text"
          > 
            <DeleteOutlined style={{ fontSize: '18px'}}/>
          </Button>
        </Space>
      )
    }
  ]

  const handleCustomerDelete = (customer: DataSourceProps) => {
    // if (response.status === 200) {
      try {
        customer.id && customerContext.Delete(customer.id);
        let index = customerData.indexOf(customer)
        const newCustomerData = [...customerData]

        newCustomerData.splice(index, 1)
        
        setCustomerData(newCustomerData)
      } finally {

      }
      
      // setFeedback({activate: true, content: 'Vendedor deletado com sucesso!',  type: 'success'})
    // } else {
      // setFeedback({activate: true, content: 'Houve um problema durante a exclusão. Tente novamente!',  type: 'error'})
    // }
  }

  const handleTableChange = (values: any) => {
    // console.log('table: ', values)

  }

  // useEffect(() => {
  //   customerContext.Get({
  //     company_name: 'Casa das rações',
  //     email: 'racoes@gmail.com',
  //     phone: '83 99999-0000',
  //     key: Date.now().toString(),
  //     id: Date.now().toString(),
  //     status: true
  //   })
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const props = {
    onChange: handleTableChange
  }
  

  useEffect(() => {
    setCustomerData(customerContext.customers)
  },[customerContext.customers])
  
  useEffect(() => {
    customerContext.Get().finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoaderPage loader={loading}>
      <TableComponent
        dataSource={customerData} 
        columns={columns}
        props={props}
      />
    </LoaderPage>
  )
}
