import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface AuthContextProps {
  signed: boolean
  user: UserProps | null
  Login(user: object): void
  Logout(): void
}

interface AuthProviderProps {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode
}

export interface UserProps {
  email: string
  name: string
  exp: number
  picture: string
  sub: string
  clientId: string
  email_verified: boolean
  hd: string
  iat: number
  credential: string
  aud: string
  admin: number
  access_token: string
}

export const AuthContext = createContext<AuthContextProps>(null!)

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
  const [user, setUser] = useState<UserProps>(null!) //{ name: 'Marcelo', email: 'ciacelojr@gmail.com'}
  const navigate = useNavigate()

  useEffect(() => {
    const storagedUser = localStorage.getItem('@App:user')
    const storagedToken = localStorage.getItem('@App:token')

    if (storagedToken && storagedUser) {
      saveUser({...JSON.parse(storagedUser)})//anchor: tornando admin só para teste
      //api.defaults.headers.Authorization = `Bearer ${storagedToken}`
    }
  }, [])

  async function Login(userData: UserProps) {
    // const response await api.post('/signin', userData)

    setUser({...userData, admin: +true })//anchor: tornando admin só para teste
    // appendFile.defaults.headers.Authorization = `Bearer ${response.data.token}`

    localStorage.setItem('@App:user', JSON.stringify({...userData, admin: +true}))//anchor: tornando admin só para teste
    localStorage.setItem('@App:token', `Bearer ${userData.access_token}`)
    navigate('/')
  }

  function Logout() {
    setUser(null!)
    localStorage.removeItem('@App:user')
    localStorage.removeItem('@App:token')
    navigate('/login')
  }

  function saveUser(user: UserProps) {
    setUser(user)
  }

  return (
    <AuthContext.Provider
      value={{signed: Boolean(user), user, Login, Logout}}
    >
      {children}
    </AuthContext.Provider>
  )
}