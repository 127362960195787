import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import RootRouter from './routes/RootRouter';

function App() {
  return (
   <>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
    >
      <RootRouter />
    </GoogleOAuthProvider>
   </>
  );
}

export default App;
