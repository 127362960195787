import { Layout as LayoutAnt, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { AuthContextProps } from 'contexts/auth/auth';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LogoGuaraves from 'assets/images/logo-guaraves.svg'

import './layout.scss'
import { User } from 'components/user';

const { Header, Content, Sider } = LayoutAnt;

interface ItemProps {
  label: string
  key: string
  admin: number
}

const adminRouters = ['Vendedores']

function sidebarOptions(auth: AuthContextProps): ItemProps[] {  
  let items: ItemProps[] = []

  const options: string[] = ['Vendedores', 'Clientes']

  options.map((title, index) => {
    const key = String(index + 1);

    if (!auth.user?.admin && Boolean(adminRouters.filter((item) => title !== item)[0])) {
      return items.push({
        key,
        label: title,
        admin: +Boolean(adminRouters.filter((item) => title === item)[0]),
        // disabled: !auth.user?.admin && Boolean(adminRouters.filter((item) => title === item)[0]),
      });
    } else if (auth.user?.admin)  {
      return items.push({
        key,
        label: title,
        admin: +Boolean(adminRouters.filter((item) => title === item)[0]),
        // disabled: !auth.user?.admin && Boolean(adminRouters.filter((item) => title === item)[0]),
      });
    }

    return null
  })
  return items
};

const Layout: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()

  let pathname = location.pathname

  const handleNavigation = (items: ItemType) => {
    const labelItem = sidebarOptions(auth).filter(item => items && items.key === item?.key)[0].label
    navigate(`${labelItem.toLowerCase()}`)  
  }

  useEffect(() => {
    function UpdateSelectedMenu() {
      let key = sidebarOptions(auth).filter(item => item.label.toLowerCase() === pathname?.split('/')[1])[0]?.key
      setSelectedMenu(key)
    }

    UpdateSelectedMenu()
  }, [pathname, auth])

  useEffect(() => {
    if (pathname === '/') {
      navigate('/clientes')
    }
  },[pathname, navigate])


  return(
    <LayoutAnt style={{ height: '100vh' }} id='layout-component-id'>
      <Header className="header">
        <User />
      </Header>
      <LayoutAnt>
        <Sider 
          width={200} 
          className="site-layout-background"
          breakpoint="lg"
          collapsedWidth="0"
        >
          <div className="marketing">
            <img src={LogoGuaraves} alt="guaraves" className="logo" />
            <h3>PORTAL DO VENDEDOR</h3>
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            selectedKeys={[selectedMenu] as string[]}
            defaultOpenKeys={['1']}
            style={{ height: 'auto', borderRight: 0 }}
            items={sidebarOptions(auth)}
            onClick={(items) => handleNavigation(items)}
          />
        </Sider>
        <LayoutAnt className="layout-content" style={{ overflowY: 'auto' }}>
          <Content
            className="site-layout-background site-layout-content"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </LayoutAnt>
      </LayoutAnt>
    </LayoutAnt>
  )
};

export default Layout;