import { DataSourceProps } from 'components/sellers/SellersTable'
import React, { createContext, useEffect, useState } from 'react'
import { api } from 'services'

interface SellerContextProps {
  sellers: DataSourceProps[]
  Delete: (seller_id: string) => void
  Update: (seller: DataSourceProps) => void
  Get: () => Promise<any>
}

export const SellersContext = createContext<SellerContextProps>(null!)

export const SellersProvider = ({ children }: { children: React.ReactNode }) => {
  const [sellers, setSellers] = useState<DataSourceProps[]>([])
  const [loading, setLoading] = useState(false)


  const Delete = (seller_id: string) => {
    setSellers(sellers.filter(item => item.id !== seller_id))
  }

  const Update = (seller: DataSourceProps) => {
    let sellersCopy = [...sellers]
    let index = sellersCopy.findIndex(item => item === seller)
    sellersCopy.splice(index, 1, seller)
    setSellers(sellersCopy)
  }

  const Get = async () => {
      const response = await api({
        method: 'get',
        url: '/members'
      })
      if (response?.status === 200 ) {
        const sellersList = Array.from(response.data, 
          (data: { props: DataSourceProps, _id: string})  =>  { return { ...data.props, id: data._id, key: data._id } })
        setSellers(sellersList)
      }
      return response
  }

  useEffect(() => {
    Get();
    // setCustomers(customerMock)
    setLoading(false)
  }, [])

  return (
    <SellersContext.Provider value={{ Delete, sellers, Update, Get }}>
      {!loading && children}
    </SellersContext.Provider>
  )
}
