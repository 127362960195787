import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

interface RequireAuthProps {
  children: JSX.Element
}

function RequireAuth({ children }: RequireAuthProps) {
  let auth = useAuth()
  let location = useLocation()

  function validToken(exp: number) {
    /* refatorar, a validação se da n somente pelo tempo, 
    * mas validar se o token ainda é permititdo na API 
    */
    // if (exp * 1000 < Date.now()) {
    //   auth.Logout()
    // }
  }

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />

  } else if (auth.user.exp) {
    validToken(auth.user.exp)
  }

  return children
}

export default RequireAuth