import { Button, Col, Divider, Form, FormRule, Row, Steps } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import React from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataSourceProps } from './customers/CustomersTable'
import { chickensFormKeys, rationFormKeys, simplifiedFormKeys } from './customers/utils'

import './formsStyles.scss'
import { LoaderPage } from './Loader'

export interface FormOptionsProps {
  label?: string
  extra?: string
  name?: string
  rules?: object[]
  tooltip?: string
  getValueProps?: (value: any) => any
  valuePropName?: string
  children: React.ReactNode | React.ReactElement | React.FC | JSX.Element | any
  style?: object
  shouldUpdate?: boolean | ((prevValue: any, curValue: any) => boolean)
}

export interface StepsProps {
  title: string
  formOptions: FormOptionsProps[]
}

interface FormsPros {
  steps: StepsProps[]
  handleSubmit: (values: any) => void
  handleSubmitToProtheus?: () => void
  initialValues: object
  buttonTitle: string
  form: FormInstance<any>
  loading: boolean
  loadingCustomerSubmitButton?: boolean
  handleNextData?: (values: any) => void
  formData?: DataSourceProps
  backToPage: string
  handleSaveAndSubmit?: (values: any) => void
  loadingSendToProtheus?: boolean
}

const { Step } = Steps

export const Forms: React.FC<FormsPros> = ({ 
    steps,
   handleSubmit,
   handleSubmitToProtheus, 
   initialValues, 
   buttonTitle, 
   form, 
   loading = true,
   loadingCustomerSubmitButton = false, 
   handleNextData,
   formData,
   backToPage,
   handleSaveAndSubmit,
   loadingSendToProtheus = false
  }) => {
  const [current, setCurrent] = useState(0)
  const [, forceUpdate] = useState({})
  const [disabledButtonNext, setDisabledButtonNext] = useState(false)
  const [isAbleProtheus, setIsAbleProtheus] = useState(false)
  const navigate = useNavigate()

  const next = () => {
    setCurrent(current + 1)
    let data = form.getFieldsValue()
    handleNextData && handleNextData(data)

    if (current === steps.length - 2 && formData) {
      validatingCustomerDataAll() //chamando função de validação do botão pro protheus
    }
  }

  const prev = () => setCurrent(current - 1)

  const onChange = (value: number) => {
    setCurrent(value)

    if (value === steps.length -1 && handleSaveAndSubmit) { 
    /* sempre que clicar diretamente pro último step, 
    deve enviar as infos para a api, 
    para caso necessário, gerar o customer_id */
      handleSaveAndSubmit(form.getFieldsValue(true))
    }
    validatingCustomerDataAll()
  }

  const validatingCustomerDataAll = () => {
    let data = form.getFieldsValue()
    console.log('form', formData)
    let errorCount = 0
      // let currentValue: DataSourceProps = {...formData, ...data}
    let currentData = {...formData, ...data}
    let currentValue: DataSourceProps = null!
    let formKeyByFormType = { "RATIONS": rationFormKeys , "SIMPLIFIED": simplifiedFormKeys, "CHICKEN": chickensFormKeys }
    // if (formData?.customer_type === 'CHICKEN') {
    //   currentValue = currentData
    // } else {
      Object.entries(currentData).map(item => {
        if (formKeyByFormType[formData?.customer_type as keyof typeof formKeyByFormType]?.includes(item[0])) {
          currentValue = {...currentValue, [item[0]]: item[1] } 
        }
        return item
      })
    // }
    // console.log('current value: ', currentValue)
      currentValue && Object.values(currentValue).map((item, index) => {
          switch (typeof item) {
            case 'string':
              if (item === "" || item == null) errorCount += 1
              // console.log('errorCount 1: ', item)
              break;
            case 'object':
              switch (Array.isArray(item)) {
                case true:
                  if (item.length === 0) errorCount += 1
              // console.log('errorCount 2: ', item)

                  break
                default:
                  if (item === "" || item == null) errorCount += 1
              // console.log('errorCount 3: ', index, item, currentValue)

                  break 
              };
              break
            default:
              break;
          }
          return item
        }
      )

      if (!currentValue) errorCount += 1
      
      setIsAbleProtheus(errorCount === 0)
  }

  
  const handleDisabledButtonNext = useCallback(() => {
    let data = form.getFieldsValue()
    let disabled = Object.values(data).filter(item => !item)
    
    let validNamesWithRequired = steps[current].formOptions.filter(item => item.rules !== undefined)
    
    let isFieldsArray: string[] = Array.from(validNamesWithRequired, item => item.name as string)
    
    
    setDisabledButtonNext(disabled.length > 0 && (
      !form.isFieldsTouched(isFieldsArray, true) ||
      !!form.getFieldsError().filter(({ errors }) => errors.length).length
      ))
    }, [current, form, steps])


    // Para desabilitar o botão de submit ao iniciar formulário
    useEffect(() => {
      forceUpdate({})
    }, [])

  useEffect(() => {
    handleDisabledButtonNext()
  }, [handleDisabledButtonNext])

  return (
    <div id='forms-components'>
      <LoaderPage loader={loading}>
        <Steps 
          current={current}
          onChange={onChange}
        >
          {steps.map(step => (
            <Step key={step.title} title={step.title} />
          ))}
        </Steps>
        <Row gutter={{ xs: 16, sm: 20, md: 24, lg: 32 }} style={{ marginTop: '4.5rem', justifyContent: 'center'}}>
          <Col className='gutter-row' xs={24} md={22} lg={20} xl={18} >
            {!loading  && <Form
              form={form}
              name="register"
              // onFinish={handleSubmit}
              onFieldsChange={handleDisabledButtonNext}
              initialValues={initialValues}
              layout='vertical'
            >
              {steps[current].formOptions.map((formItem, index) => (
                <Form.Item
                  {...formItem}
                  key={index}
                >
                  {formItem.children}
                </Form.Item>
              ))}
              <Divider />
              <Form.Item style={{ marginBottom: 0, maxWidth: '58rem', width: '100%', float: 'right' }} className="form-item-button">
                <Form.Item style={{ display: 'inline-block', width: '8rem', marginRight: '0.8rem' }}>
                  <Button danger className='cancel-btn'  onClick={() => navigate(`${backToPage}`)} style={{ width: '7rem', padding: '0.5rem' }}>
                    Cancelar
                  </Button>
                </Form.Item>
                {current > 0 && (
                  <Form.Item style={{ display: 'inline-block', width: '8rem', marginRight: '1rem' }}>
                    <Button type='ghost' onClick={prev} >
                      Voltar
                    </Button>
                  </Form.Item>
                )}
                {current < steps.length - 1 && (
                  <Form.Item style={{ display: 'inline-block', width: '8rem' }} shouldUpdate> 
                    {() => (
                      <Button 
                        type='primary'
                        htmlType={current === steps.length - 2 ? 'submit' : 'button'} 
                        onClick={current === steps.length - 2 && handleSaveAndSubmit ? () => { 
                          handleSaveAndSubmit(form.getFieldsValue(true))
                          next()
                        } : next}
                        // disabled={disabledButtonNext}
                      >
                        Próximo
                      </Button>
                    )}
                    </Form.Item>
                )}
                {current === steps.length - 1 && (
                  <Form.Item style={{ display: 'inline-block', width: '8rem' }} shouldUpdate>
                    {() => (
                      <Button 
                        type='primary' 
                        onClick={() => handleSubmit(form.getFieldsValue(true))}
                        disabled={loadingCustomerSubmitButton}
                        loading={loadingCustomerSubmitButton}
                      >
                        {buttonTitle}
                      </Button>
                    )}
                  </Form.Item>
                )}
                {current === steps.length - 1 && isAbleProtheus && (
                  <Form.Item style={{ display: 'inline-block', width: '17rem' }} shouldUpdate>
                    {() => (
                      <Button 
                        type='primary' 
                        onClick={handleSubmitToProtheus}
                        disabled={loadingCustomerSubmitButton}
                        loading={loadingSendToProtheus}
                      >
                        Enviar ao Protheus
                      </Button>
                    )}
                  </Form.Item>
                )}
                </Form.Item>
            </Form>}
          </Col>
        </Row>
      </LoaderPage>
    </div>
  )
}
