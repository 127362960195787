import { notification } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'

export interface FeedbackMessageProps {
  type: 'success' | 'error' | 'warning'
  content: string | React.ReactNode
  activate: boolean
  setActivate?: (value: boolean) => void
}

export const FeedbackMessage = ({ type, content, activate, setActivate }: FeedbackMessageProps) => {

  const handleFeedback = useCallback(() => {
    notification[type]({
      description: content,
      message: '',
      onClose() {
        setActivate && setActivate(false)
      },
    })
  }, [content, setActivate, type])


  useEffect(() => {
    if (activate) handleFeedback()
  }, [activate, handleFeedback])


  return null

}
