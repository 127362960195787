import axios from "axios";

interface APIProps {
  method: 'get' | 'post' | 'put' | 'delete'
  url: string
  data?: object
  params?: object
  headers?: object
}

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use(async config => {
  const token = localStorage.getItem('@App:token') as string
  config.headers = { ...config.headers }
  if (token && config.headers) { 
    config.headers.Authorization = token
  }

  return config
})

export const api = async ({ method, url, data = {}, params = {}, headers = {} }: APIProps) => {
  return http.request({
    method,
    url,
    data,
    params,
    headers
  })
  .then(response => response)
  .catch(error => {
    if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
      localStorage.clear()
      window.location.replace('/login')
    }

    return error.response
  })
}