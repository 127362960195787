import { Checkbox, Input } from 'antd'
import { Forms, StepsProps, FormOptionsProps } from 'components/Forms'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Form } from 'antd'
import { api } from 'services'
import { FeedbackMessage, FeedbackMessageProps } from 'utils/FeedbackMessage'
import { SellersContext } from 'contexts/sellers'
import { useNavigate, useParams } from 'react-router-dom'
import { DataSourceProps } from './SellersTable'

export const SellerForm = ({ type }: { type: 'create' | 'edit' }) => {
  const [initialValues, setInitialValues] = useState<DataSourceProps>({ 'is_admin': false, name: '', email: '' })
  const [checkedIsAdmin, setCheckedIsAdmin] = useState(false)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState<FeedbackMessageProps>({ activate: false, content: '', type: 'success' })
  const sellerContext = useContext(SellersContext)
  const { id } = useParams()
  const navigate = useNavigate()

  const handleCheckedIsAdmin = (e: CheckboxChangeEvent) => {
    setCheckedIsAdmin(e.target.checked)
  }

  const formOptions: FormOptionsProps[] = useMemo(() => 
  [
    {
      name: 'name',
      label: 'Nome',
      rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
      children: <Input />
    },
    {
      name: 'email',
      label: 'E-mail',
      rules: [
        { required: true, message: 'Por favor, preencha este campo.' },
        { type: 'email', message: 'Este não é um e-mail válido.' }
      ],
      children: <Input/>
    },
    {
      name: 'is_admin',
      valuePropName: 'checked',
      children: <Checkbox defaultChecked={checkedIsAdmin} onChange={handleCheckedIsAdmin}>
        Conta de administrador?
      </Checkbox>
    },
    // { //exemplo de filho com componentes inline
    //   children: <Form.Item>
    //     {[{
    //       name: 'rg',
    //       label: 'RG',
    //       rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
    //       children: <Input />,
    //       style: { display: 'inline-block', width: 'calc(60% - 1px)', marginRight: '1rem' }
    //     },
    //     {
    //       name: 'emissor',
    //       label: 'orgão emissor',
    //       rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
    //       children: <Input />,
    //       style: { display: 'inline-block', width: 'calc(37%)' }
    //     }].map(item => (
    //       <Form.Item 
    //         {...item} 
    //         key={item.name}
    //       >
    //         {item.children}
    //       </Form.Item>
    //     ))}
    //   </Form.Item>
    // }
  ], [checkedIsAdmin, initialValues])
    
  

  const formOptions2 = useMemo((): FormOptionsProps[] => {
    return [
      {
        name: 'street',
        label: 'Logradouro',
        rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
        children: <Input />
      },
      {
        name: 'number',
        label: 'N°',
        rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
        children: <Input type={'number'} />
      }
    ]
  }, [])

  const steps = useMemo((): StepsProps[] => {
    return [ 
      {
        title: 'Dados Pessoais',
        formOptions
      },
      // {
      //   title: 'Endereço',
      //   formOptions: formOptions2
      // }
    ]
  }, [formOptions])


  const onSubmit = async (values: any) => {
    try {
      setLoading(true)
      // sellerContext[type === 'create' ? 'Create' : 'Update']({ ...values, key: Date.now() } as DataSourceProps)
      // setFeedback({activate: true, content: `Vendedor ${type === 'create' ? 'cadastrado' : 'editado'} com sucesso!`, type: 'success'})
      const response =  type === 'create' ? await api({
        method: 'post',
        url: `/members`,
        data: values,
      }) : await api({
        method: 'put',
        url: `/members/${id}`,
        data: values
      })

      if (response.status === 201 || response.status === 200) {
        type === 'edit' ?  
        sellerContext.Update({ ...values, key: Date.now() } as DataSourceProps) :
        sellerContext.Get()

        setFeedback({activate: true, content: `Vendedor ${type === 'create' ? 'cadastrado' : 'editado'} com sucesso!`, type: 'success'})
        setTimeout(() => navigate('/vendedores'), 200)
      } else {
        setFeedback({activate: true, content: `Houve um erro durante ${type === 'create' ? 'o cadastro' : 'a edição'}. Tente novamente!`, type: 'error'})
      } 

    
    } catch (error) {
      setFeedback({activate: true, content: `Houve um erro durante ${type === 'create' ? 'o cadastro' : 'a edição'}. Tente novamente!`, type: 'error'})
    } finally {
      setLoading(false)
      // navigate('/vendedores')
      setTimeout(() => setFeedback({activate: false, content: '', type: 'success'}), 1000)
      // setActivated(false)
    }
    
  }

  useEffect(() => {
    if (type === 'edit') {
      setLoading(true)
    }
  }, [type])
  

  useEffect(() => {
    if (id) {
      let seller = sellerContext.sellers.filter(seller => String(seller.id) === String(id))[0]
      setInitialValues(seller)

      form.setFieldsValue(seller)
      setTimeout(() => setLoading(false), 500);
    }
  }, [form, id, sellerContext.sellers])

  return (
    <div>
      <Forms 
        steps={steps}
        buttonTitle={type === 'create' ? 'Criar' : 'Editar'}
        initialValues={initialValues}
        handleSubmit={onSubmit}
        form={form}
        loading={loading}
        backToPage="/vendedores"
      />
      <FeedbackMessage 
          activate={feedback.activate}
          content={feedback.content}
          type={feedback.type}
        />
    </div>
  )
}
