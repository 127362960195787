import React from 'react'
import { SellersProvider } from 'contexts/sellers'
import { Outlet } from 'react-router-dom'

export const SellerRoot = () => {
  return (
    <SellersProvider>
      <Outlet />
    </SellersProvider>
  )
}
