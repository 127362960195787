import { Select, Form, Input, DatePicker, InputNumber, Checkbox, Row, Col, Radio, Button, FormInstance, UploadFile } from "antd"
import { FormOptionsProps } from "components/Forms"
import UploadComponent from "components/UploadComponent"
import dayjs from "dayjs"
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { BanksProps, CNAEProps, UFProps } from "utils"
import { MaskInput } from "utils/MaskInput"
import { DataSourceProps, DocumentProps } from "../CustomersTable"
import moment from 'moment';
import { api } from "services"
import React, { SyntheticEvent } from "react"
import { SearchOutlined } from "@ant-design/icons"
import { FeedbackMessageProps } from "utils/FeedbackMessage"


dayjs.extend(weekday)
dayjs.extend(localeData)

const { Option } = Select
const formatPtBr = ['DD/MM/YYYY', 'DD/MM/YY']

interface formOptionPropsOne {
  customerInitial: DataSourceProps 
  setFormType: (value: React.SetStateAction<"CHICKEN" | "RATIONS" | "SIMPLIFIED">) => void
  ufs: UFProps
  cnae: CNAEProps[]
  cnpj: string
  setCNPJ: (value: string) => void
  form: FormInstance<any>
  loading: boolean
  setLoading: (value: boolean) => void
  setFeedback: (values: FeedbackMessageProps) => void
}

interface formOptionPropsTwo {
  ufs: UFProps
  banks?: BanksProps[]
}

interface formOptionPropsFour {
  belongsNetwork: boolean
  setBelongsNetwork: (value: boolean) => void
  ufs?: UFProps
}

interface formOptionPropsFive {
  setEnterPriseSuccessor: (value: boolean) => void 
  enterpriseSuccessor: boolean
}

interface formOptionsPropsSix {
  customerResponse: DataSourceProps & { _id: string}
  customerInitial: DataSourceProps
}

export interface uploadFileProps {
  documentType: 'CNH' | 'RG' | 'CNPJ_CARD' | 'SOCIAL_CONTRACT' | 'BILL' | 'CUSTOMER_TERM'
  file: any
  onSuccess: () => void
  onError: () => void
}

const validateDate = (value: any) => {
  let date = value ? value : null
  return { value: moment(date).isValid() ? moment(date) : ''}
}

const legalNature = [
  '0000 Natureza jurídica não identificada',
  '1015 Órgão Público do Poder Executivo Federal',
  '1023 Órgão Público do Poder Executivo Estadual ou do Distrito Federal',
  '1031 Órgão Público do Poder Executivo Municipal',
  '1040 Órgão Público do Poder Legislativo Federal',
  '1058 Órgão Público do Poder Legislativo Estadual ou do Distrito Federal',
  '1066 Órgão Público do Poder Legislativo Municipal',
  '1074 Órgão Público do Poder Judiciário Federal',
  '1082 Órgão Público do Poder Judiciário Estadual',
  '1104 Autarquia Federal',
  '1112 Autarquia Estadual ou do Distrito Federal',
  '1120 Autarquia Municipal',
  '1139 Fundação Pública de Direito Público Federal',
  '1147 Fundação Pública de Direito Público Estadual ou do Distrito Federal',
  '1155 Fundação Pública de Direito Público Municipal',
  '1163 Órgão Público Autônomo Federal',
  '1171 Órgão Público Autônomo Estadual ou do Distrito Federal',
  '1180 Órgão Público Autônomo Municipal',
  '1198 Comissão Polinacional',
  '1201 Fundo Público',
  '1210 Consórcio Público de Direito Público (Associação Pública)',
  '1228 Consórcio Público de Direito Privado',
  '1236 Estado ou Distrito Federal',
  '1244 Município',
  '1252 Fundação Pública de Direito Privado Federal',
  '1260 Fundação Pública de Direito Privado Estadual ou do Distrito Federal',
  '1279 Fundação Pública de Direito Privado Municipal',
  '2011 Empresa Pública',
  '2038 Sociedade de Economia Mista',
  '2046 Sociedade Anônima Aberta',
  '2054 Sociedade Anônima Fechada',
  '2062 Sociedade Empresária Limitada',
  '2070 Sociedade Empresária em Nome Coletivo',
  '2089 Sociedade Empresária em Comandita Simples',
  '2097 Sociedade Empresária em Comandita por Ações',
  '2100 Sociedade Mercantil de Capital e Industria',
  '2127 Sociedade em Conta de Participação',
  '2135 Empresário (Individual)',
  '2143 Cooperativa',
  '2151 Consórcio de Sociedades',
  '2160 Grupo de Sociedades',
  '2178 Estabelecimento, no Brasil, de Sociedade Estrangeira',
  '2194 Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira',
  '2216 Empresa Domiciliada no Exterior',
  '2224 Clube/Fundo de Investimento',
  '2232 Sociedade Simples Pura',
  '2240 Sociedade Simples Limitada',
  '2259 Sociedade Simples em Nome Coletivo',
  '2267 Sociedade Simples em Comandita Simples',
  '2275 Empresa Binacional',
  '2283 Consórcio de Empregadores',
  '2291 Consórcio Simples',
  '2305 Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
  '2313 Empresa Individual de Responsabilidade Limitada (de Natureza Simples)',
  '2321 Sociedade Unipessoal de Advogados',
  '3034 Serviço Notarial e Registral (Cartório)',
  '3069 Fundação Privada',
  '3077 Serviço Social Autônomo',
  '3085 Condomínio Edilício',
  '3107 Comissão de Conciliação Prévia',
  '3115 Entidade de Mediação e Arbitragem',
  '3123 Partido Político',
  '3131 Entidade Sindical',
  '3204 Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras',
  '3212 Fundação ou Associação Domiciliada no Exterior',
  '3220 Organização Religiosa',
  '3239 Comunidade Indígena',
  '3247 Fundo Privado',
  '3255 Órgão de Direção Nacional de Partido Político',
  '3263 Órgão de Direção Regional de Partido Político',
  '3271 Órgão de Direção Local de Partido Político',
  '3280 Comitê Financeiro de Partido Político',
  '3298 Frente Plebiscitária ou Referendária',
  '3301 Organização Social (OS)',
  '3999 Associação Privada',
  '4014 Empresa Individual Imobiliária',
  '4022 Segurado Especial',
  '4081 Contribuinte individual',
  '4090 Candidato a Cargo Político Eletivo',
  '4111 Leiloeiro',
  '4120 Produtor Rural (Pessoa Física)',
  '5010 Organização Internacional',
  '5029 Representação Diplomática Estrangeira',
  '5037 Outras Instituições Extraterritoriais',
  '8885 Natureza Jurídica Inválida'
]
const legalNatureCode = [
  '0000',
  '1015',
  '1023',
  '1031',
  '1040',
  '1058',
  '1066',
  '1074',
  '1082',
  '1104',
  '1112',
  '1120',
  '1139',
  '1147',
  '1155',
  '1163',
  '1171',
  '1180',
  '1198',
  '1201',
  '1210',
  '1228',
  '1236',
  '1244',
  '1252',
  '1260',
  '1279',
  '2011',
  '2038',
  '2046',
  '2054',
  '2062',
  '2070',
  '2089',
  '2097',
  '2100',
  '2127',
  '2135',
  '2143',
  '2151',
  '2160',
  '2178',
  '2194',
  '2216',
  '2224',
  '2232',
  '2240',
  '2259',
  '2267',
  '2275',
  '2283',
  '2291',
  '2305',
  '2313',
  '2321',
  '3034',
  '3069',
  '3077',
  '3085',
  '3107',
  '3115',
  '3123',
  '3131',
  '3204',
  '3212',
  '3220',
  '3239',
  '3247',
  '3255',
  '3263',
  '3271',
  '3280',
  '3298',
  '3301',
  '3999',
  '4014',
  '4022',
  '4081',
  '4090',
  '4111',
  '4120',
  '5010',
  '5029',
  '5037',
  '8885'
]

export function FormOption({ customerInitial, setFormType, ufs, cnae, cnpj, setCNPJ , form, loading, setLoading, setFeedback}: formOptionPropsOne): FormOptionsProps[] {
  const handleCnpjConsult = async (cnpj: string) => {
    setLoading(true)
    const response = await api({
      method:  'get',
      url: `/prospecta/cnpj/${cnpj}`
    })

    if (response?.status === 200) {
      let data = {
        ...response.data, 
        legal_nature: legalNature.find(code => code.includes(response.data.legal_nature)), 
        legal_nature_code: legalNatureCode.find(code => response.data.legal_nature.includes(code) ),
        cnae: cnae.find(codeName => response.data.cnae.includes(codeName.id))?.descricao,
        cnae_code: response.data.cnae || customerInitial.cnae_code,
      }
      form.setFieldsValue({...customerInitial, ...data, })

      if (response.data?.registration_status && response.data.registration_status === '3') {
        setFeedback({activate: true, content: `A situação cadastral desse CNPJ, recebeu baixa!`, type: 'warning'})
      }
    } else {
      setFeedback({activate: true, content: `Este CNPJ não foi encontrado na nossa base de dados`, type: 'error'})
    }

    setLoading(false)

  }

  const handleChangeInput = (e: SyntheticEvent & { maskedValue: string; unmaskedValue: string } ) => {
    setCNPJ(e.unmaskedValue)
  }

  // const handleChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
  //   cnpj = e.currentTarget.value
  // }

  return [
    {
      children: <h2 className='form-subtitle'>Dados do cliente</h2>
    },
    {
      name: 'customer_type',
      label: 'Tipo de cadastro',
      // valuePropName: 'option',
      rules: [{ required: true, message: 'Por favor, selecione uma opção.'}],
      children: 
        <Select  
          onChange={(value) => setFormType(value)}
          style={{ width: '100%', maxWidth: '26.5rem' }}
        >
          <Option value='CHICKEN'>Industrializado</Option>
          <Option value='RATIONS'>Rações</Option>
          <Option value='SIMPLIFIED'>Simplificado</Option>
        </Select>,
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'branch',
            label: 'Filial de Cadastro',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
          {
            name: 'company_opening_date',
            label: 'Data de abertura',
            // initialValue: dayjs(),
            getValueProps: (value: any) => (validateDate(value)),
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <DatePicker  format={formatPtBr} />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'size',
            label: 'Porte',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children:  <Select /*value={customerInitial['size']}*/>
              <Option value='1'>Mei</Option>
              <Option value='2'>Micro Empresa</Option>
              <Option value='3'>Pequena Empresas</Option>
              <Option value='4'>Normal</Option>
              <Option value='5'>Outros</Option>
            </Select>,
          },
          {
            name: 'cnpj',
            label: 'CNPJ',
            children:
              <MaskInput onChange={handleChangeInput}  mask="00.000.000/0000-00" style={{ minWidth: '200px'}} />
          },
          {
            style: { height: '58px', paddingTop: '26px'},
            name: 'search',
            children:
              <Button 
                onClick={() => handleCnpjConsult(cnpj)} 
                style={{ width: '50px' }}
                loading={loading}
                type="primary"
                icon={<SearchOutlined />} 
              />
            // shouldUpdate: true
          },
          {
            name: 'uf',
            label: 'Estado',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children:  <Select /*value={customerInitial['size']}*/> 
              {ufs && ufs.geonames.map(uf => (
                <Option value={uf.name} key={uf.name}>{uf.name}</Option>
              ))}
            </Select>, //anchor: refatorar o 'value'
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-four with-search-button'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'company_name',
            label: 'Razão social',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: 'cep',
            label: 'CEP',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <InputNumber controls={false} />
          },
          {
            name: 'phone',
            label: 'Telefone',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <MaskInput mask="(00) 0 0000-0000" max={4} />,
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-three-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'fantasy_name',
            label: 'Fantasia',
            rules: [{ message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: 'registration_status',
            label: 'Situação cadastral',
            // valuePropName: 'option',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Select>
              <Option value='1'>Ativa</Option>
              <Option value='2'>Ativa Exterior</Option>
              <Option value='3'>Baixada</Option>
              <Option value='4'>Baixada Exterior</Option>
              <Option value='5'>Suspensa</Option>
              <Option value='6'>Suspensa Exterior</Option>
              <Option value='7'>Nula</Option>
              <Option value='8'>Nula Exterior</Option>
              <Option value='9'>Inapta</Option>
              <Option value='10'>Inapta Exterior</Option>
            </Select>,
          },
          {
            name: 'registration_status_date',
            label: 'Data da situação cadastral',
            // rules: [{ required: true, message: 'Por favor, selecione uma data.' }],
            getValueProps: (value: any) => (validateDate(value)),
            children: <DatePicker format={formatPtBr} style={{width: '100%'}}/>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-three-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          // { //REMOVENDO
          //   name: 'total_amount',
          //   label: 'Total',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <InputNumber type='number' style={{ width: '100%' }} />,
          // },
          {
            name: 'cnae',
            label: 'CNAE',
            // valuePropName: 'option',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Select /*value={customerInitial['size']}*/>
              {cnae.length > 0 && cnae.map(item => (
                <Option value={item.id} key={item.id}>{item.id} - {item.descricao}</Option>
              ))}
            </Select>,
          },
          {
            name: 'cnae_code',
            label: 'Código CNAE',
            // valuePropName: 'option',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Select /*value={customerInitial['size']}*/>
              {cnae.length > 0 && cnae.map(item => (
                <Option value={item.id} key={item.id}>{item.id}</Option>
              ))}
            </Select>,
          }
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-two-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    // {
    //   children: <Form.Item>
    //     {[
          // {
          //   name: 'related_company',
          //   label: 'Empresa relacionada',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
          // { //removendo
          //   name: 'rank',
          //   label: 'Rank',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />
          // },
          // { //removendo
          //   name: 'google_phone',
          //   label: 'Telefone Google',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <MaskInput mask="0000 000 0000" />,
          // },
          // {// removendo
          //   name: 'tags',
          //   label: 'Tags',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
    //     ].map((item) => (
    //       <Form.Item 
    //         {...item} 
    //         key={item.name}
    //         className='form-inline-responsive'
    //       >
    //         {item.children}
    //       </Form.Item>
    //     ))}
    //   </Form.Item>
    // },
    {
      children: <Form.Item>
        {[
          {
            name: 'public_place',
            label: 'Logradouro',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: 'number',
            label: 'N°',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
          {
            name: 'complement',
            label: 'Complemento',
            rules: [{ message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-three-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'neighborhood',
            label: 'Bairro',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: 'city',
            label: 'Cidade',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
          {
            name: 'email',
            label: 'E-mail',
            rules: [
              { required: true, message: 'Por favor, preencha este campo.' },
              { type: 'email', message: 'Este não é um e-mail válido.' }
            ],
            children: <Input />,
          },
        ].map((item: FormOptionsProps) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-three'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
  ]
}

export function formOptionTwo({ ufs, setEnterPriseSuccessor, enterpriseSuccessor}: formOptionPropsTwo & formOptionPropsFive): FormOptionsProps[] {
  return [
    {
      children: <h2 className='form-subtitle'>Dados do cliente</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'capital',
            label: 'Capital',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input type="number"/>
          },
          {
            name: 'legal_nature',
            label: 'Natureza jurídica',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Select>
              {legalNature.map(item => (
                <Option value={item} key={item}>{item}</Option>
              ))}
            </Select>
          },
          {
            name: 'legal_nature_code',
            label: 'Código Natureza Jurídica',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Select style={{ width: '100%' }}>
              {legalNatureCode.map(item => (
                <Option value={item} key={item}>{item}</Option>
              ))}
            </Select>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-three-center'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          // { //removendo
          //   name: 'search_count',
          //   label: 'Count Busca',
          //   rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
          {
            name: 'phone_contact',
            label: 'Telefone',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <MaskInput mask="(00) 0 0000-0000" />,
          },
          // { //removendo
          //   name: 'phone_classification',
          //   label: 'Classificação telefone',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children:  <Select /*value={customerInitial['size']}*/> 
          //     {/* {ufs && ufs.geonames.map(uf => ( */}
          //       <Option value={1} key={1}>{1}</Option>
          //     {/* ))} */}
          //   </Select>, //anchor: refatorar o 'value'
          // },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    // { //removendo
    //   name: 'wallet',
    //   label: 'Carteira',
    //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
    //   children: <Select /*value={customerInitial['size']}*/ style={{ maxWidth: '25rem'}}> 
    //   {/* {ufs && ufs.geonames.map(uf => ( */}
    //     <Option value={1} key={1}>{1}</Option>
    //   {/* ))} */}
    //   </Select>, //anchor: refatorar o 'value'
    // },
    {
      children: <h2 className='form-subtitle' style={{ marginTop: '1.7rem' }}>Sócios/Acionistas</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['partner', 'name'],
            label: 'Nome',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: ['partner', 'cpf'],
            label: 'CPF',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <MaskInput mask="000.000.000-00" />
          },
          {
            name: ['partner', 'rg'],
            label: 'RG',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['partner', 'rg_issuer'],
            label: 'Orgão emissor',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: ['partner', 'role'],
            label: 'Cargo',
            // valuePropName: 'option',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
            // <Select>
            /* <Option value='1'>Ativa</Option>
              <Option value='2'>Ativa Exterior</Option>
              <Option value='3'>Baixada</Option>
              <Option value='4'>Baixada Exterior</Option>
              <Option value='5'>Suspensa</Option>
              <Option value='6'>Suspensa Exterior</Option>
              <Option value='7'>Nula</Option>
              <Option value='8'>Nula Exterior</Option>
              <Option value='9'>Inapta</Option>
              <Option value='10'>Inapta Exterior</Option>
            </Select>, */
          },
          {
            name: ['partner', 'public_place'],
            label: 'Endereço',
            rules: [{ required: true, message: 'Por favor, selecione uma data.' }],
            children: <Input />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three-right'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['partner', 'number'],
            label: 'N°',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <InputNumber type='number' style={{ width: '100%' }}/>,
          },
          // {
          //   name: 'partner_compliment',
          //   label: 'Complemento',
          //   valuePropName: 'option',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
          {
            name: ['partner', 'neighborhood'],
            label: 'Bairro',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
          {
            name: ['partner', 'city'],
            label: 'Cidade',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          }
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['partner', 'uf'],
            label: 'UF',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children:  <Select /*value={customerInitial['size']}*/> 
              {ufs && ufs.geonames.map(uf => (
                <Option value={uf.name} key={uf.name}>{uf.name}</Option>
              ))}
            </Select>,
          },
          {
            name: ['partner', 'cep'],
            label: 'CEP',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <InputNumber controls={false} />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <h2 className='form-subtitle'>Dados de Contrato Social</h2>
    },
    {
      children: <Form.Item>
        {[
          // {
          //   name: ['social_contract', 'registered_capital'],
          //   label: 'Capital registrado',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />
          // },
          {
            name: ['social_contract', 'activity_start'],
            label: 'Início de atividade',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            getValueProps: (value: any) => (validateDate(value)),
            children: <DatePicker format={formatPtBr} style={{ width: '100%' }} />
          },
          // {
          //   name: ['social_contract', 'integralized'],
          //   label: 'Integralizado',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />
          // },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['social_contract', 'cnpj_previous_company'],
            label: 'CNPJ',
            // rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
            children: <MaskInput mask="00.000.000/0000-00" />,
          },
          {
            name: ['social_contract', 'is_sucessor'],
            valuePropName: 'checked',
            label: ' ',
            children: <Checkbox defaultChecked={enterpriseSuccessor} onChange={() => setEnterPriseSuccessor(!enterpriseSuccessor)}>
                É sucessora de alguma empresa?
              </Checkbox>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: enterpriseSuccessor && <Form.Item>
        {[
          {
            name: ['social_contract', 'previous_company'],
            label: 'Sucessor de qual empresa',
            rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: ['social_contract', 'previous_public_place'],
            label: 'Local',
            children: <Input />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-two'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['social_contract', 'previous_company_local_reg_number'],
            label: 'Registro',
            rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          // {
          //   name: ['social_contract', 'previous_company_local_reg_number'],
          //   label: 'N°',
          //   children: <Input type="number"/>
          // },
          {
            name: ['social_contract', 'participations_in_previous'],
            label: 'Participação',
            children: <Input type="number" addonAfter={<span>(%)</span>}/>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-two-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
  ]
}

export function formOptionTwoRation({ ufs, setEnterPriseSuccessor, enterpriseSuccessor}: formOptionPropsTwo & formOptionPropsFive): FormOptionsProps[] {
  return [
    {
      children: <h2 className='form-subtitle'>Dados do cliente</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'capital',
            label: 'Capital',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input type="number"/>
          },
          {
            name: 'legal_nature',
            label: 'Natureza jurídica',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Select>
              {legalNature.map(item => (
                <Option value={item} key={item}>{item}</Option>
              ))}
            </Select>
          },
          {
            name: 'legal_nature_code',
            label: 'Código Natureza Jurídica',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Select style={{ width: '100%' }}>
              {legalNatureCode.map(item => (
                <Option value={item} key={item}>{item}</Option>
              ))}
            </Select>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-three-center'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          // { //removendo
          //   name: 'search_count',
          //   label: 'Count Busca',
          //   rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
          {
            name: 'phone_contact',
            label: 'Telefone',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <MaskInput mask="(00) 0 0000-0000" />,
          },
          // { //removendo
          //   name: 'phone_classification',
          //   label: 'Classificação telefone',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children:  <Select /*value={customerInitial['size']}*/> 
          //     {/* {ufs && ufs.geonames.map(uf => ( */}
          //       <Option value={1} key={1}>{1}</Option>
          //     {/* ))} */}
          //   </Select>, //anchor: refatorar o 'value'
          // },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    // { //removendo
    //   name: 'wallet',
    //   label: 'Carteira',
    //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
    //   children: <Select /*value={customerInitial['size']}*/ style={{ maxWidth: '25rem'}}> 
    //   {/* {ufs && ufs.geonames.map(uf => ( */}
    //     <Option value={1} key={1}>{1}</Option>
    //   {/* ))} */}
    //   </Select>, //anchor: refatorar o 'value'
    // },
    {
      children: <h2 className='form-subtitle' style={{ marginTop: '1.7rem' }}>Sócios/Acionistas</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['partner', 'name'],
            label: 'Nome',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: ['partner', 'cpf'],
            label: 'CPF',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <MaskInput mask="000.000.000-00" />
          },
          {
            name: ['partner', 'rg'],
            label: 'RG',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['partner', 'rg_issuer'],
            label: 'Orgão emissor',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: ['partner', 'role'],
            label: 'Cargo',
            // valuePropName: 'option',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
            // <Select>
            /* <Option value='1'>Ativa</Option>
              <Option value='2'>Ativa Exterior</Option>
              <Option value='3'>Baixada</Option>
              <Option value='4'>Baixada Exterior</Option>
              <Option value='5'>Suspensa</Option>
              <Option value='6'>Suspensa Exterior</Option>
              <Option value='7'>Nula</Option>
              <Option value='8'>Nula Exterior</Option>
              <Option value='9'>Inapta</Option>
              <Option value='10'>Inapta Exterior</Option>
            </Select>, */
          },
          {
            name: ['partner', 'public_place'],
            label: 'Endereço',
            rules: [{ required: true, message: 'Por favor, selecione uma data.' }],
            children: <Input />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three-right'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['partner', 'number'],
            label: 'N°',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <InputNumber type='number' style={{ width: '100%' }}/>,
          },
          // {
          //   name: 'partner_compliment',
          //   label: 'Complemento',
          //   valuePropName: 'option',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
          {
            name: ['partner', 'neighborhood'],
            label: 'Bairro',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
          {
            name: ['partner', 'city'],
            label: 'Cidade',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          }
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['partner', 'uf'],
            label: 'UF',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children:  <Select /*value={customerInitial['size']}*/> 
              {ufs && ufs.geonames.map(uf => (
                <Option value={uf.name} key={uf.name}>{uf.name}</Option>
              ))}
            </Select>,
          },
          {
            name: ['partner', 'cep'],
            label: 'CEP',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <InputNumber controls={false} />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <h2 className='form-subtitle'>Dados de Contrato Social</h2>
    },
    {
      children: <Form.Item>
        {[
          // {
          //   name: ['social_contract', 'registered_capital'],
          //   label: 'Capital registrado',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />
          // },
          {
            name: ['social_contract', 'activity_start'],
            label: 'Início de atividade',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            getValueProps: (value: any) => (validateDate(value)),
            children: <DatePicker format={formatPtBr} style={{ width: '100%' }} />
          },
          // {
          //   name: ['social_contract', 'integralized'],
          //   label: 'Integralizado',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />
          // },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['social_contract', 'cnpj_previous_company'],
            label: 'CNPJ',
            // rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
            children: <MaskInput mask="00.000.000/0000-00" />,
          },
          {
            name: ['social_contract', 'is_sucessor'],
            valuePropName: 'checked',
            label: ' ',
            children: <Checkbox defaultChecked={enterpriseSuccessor} onChange={() => setEnterPriseSuccessor(!enterpriseSuccessor)}>
                É sucessora de alguma empresa?
              </Checkbox>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: enterpriseSuccessor && <Form.Item>
        {[
          {
            name: ['social_contract', 'previous_company'],
            label: 'Sucessor de qual empresa',
            rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          {
            name: ['social_contract', 'previous_public_place'],
            label: 'Local',
            children: <Input />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-two'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['social_contract', 'previous_company_local_reg_number'],
            label: 'Registro',
            rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
            children: <Input />,
          },
          // {
          //   name: ['social_contract', 'previous_company_local_reg_number'],
          //   label: 'N°',
          //   children: <Input type="number"/>
          // },
          {
            name: ['social_contract', 'participations_in_previous'],
            label: 'Participação',
            children: <Input type="number" addonAfter={<span>(%)</span>}/>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-two-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
  ]
}

export function formOptionThree({ ufs, banks }: formOptionPropsTwo): FormOptionsProps[] {
  return [
    {
      children: <h2 className='form-subtitle'>Referências comerciais (Fornecedores)</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'supplier_name',
            label: 'Fornecedor',
            children: <Input />
          },
          {
            name: 'supplier_public_place',
            label: 'Endereço',
            children: <Input />
          },
          {
            name: 'supplier_number',
            label: 'N°',
            children: <Input />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-three-center'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          // {
          //   name: 'supplier_complement',
          //   label: 'Complemento',
          //   rules: [{ required: false, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
          {
            name: 'supplier_neighborhood',
            label: 'Bairro',
            children: <Input />
          },
          {
            name: 'supplier_city',
            label: 'Cidade',
            children:  <Input />
          },
          {
            name: 'supplier_uf',
            label: 'UF',
            children:  <Select /*value={customerInitial['size']}*/> 
              {ufs && ufs.geonames.map(uf => (
                <Option value={uf.name} key={uf.name}>{uf.name}</Option>
              ))}
            </Select>,
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive-three'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      name: 'supplier_phone',
      label: 'Telefone',
      children: <MaskInput mask="(00) 0 0000-0000" style={{ width: '100%', maxWidth: '25rem'}} />,
    },
  ]
}

export function formOptionFour({ belongsNetwork, setBelongsNetwork }: formOptionPropsFour): FormOptionsProps[] {
  return [ 
    {
      children: <h2 className='form-subtitle'>Dados da rede</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'belongsNetwork',
            valuePropName: 'checked',
            children: <Checkbox checked={belongsNetwork} onChange={() => setBelongsNetwork(!belongsNetwork)}>
              Pertence alguma rede?
            </Checkbox>
          },
          belongsNetwork && {
            name: 'network_name',
            label: 'Nome da rede',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
        ].map((item) => {
          if (item) {
            return (
              <Form.Item 
                {...item} 
                key={item.name}
                className='form-inline-responsive'
              >
                {item.children}
              </Form.Item>
            )
          }
          return null
        })}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
           {
            name: 'has_cold_camara',
            label: 'Estrutura da loja',
            valuePropName: 'checked',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            style: { width: 'calc(40% - 12px)', maxWidth: '200px' },
            children: 
                    <Checkbox defaultChecked={false} style={{ lineHeight: '32px' }}>
                      Tem câmara fria?
                    </Checkbox>
              //     <Col span={15}>
              //       <Checkbox value="resfriados" style={{ lineHeight: '32px' }}>
              //         Tem equipamentos P/ resfriados?
              //       </Checkbox>
              //     </Col>
              //   </Row>
              // </Checkbox.Group>
          },
          {
            name: 'has_cold_tools',
            valuePropName: 'checked',
            label: ' ',
            style: { width: 'calc(40% - 12px)', maxWidth: '300px' },
            children: 
                    <Checkbox defaultChecked={false} style={{ lineHeight: '32px' }}>
                       Tem equipamentos P/ resfriados?
                    </Checkbox>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive'
            style={item.style}
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      name: 'checkouts_qtd_enum',
      label: 'Se for supermercado',
      // valuePropName: 'checked',
      children: 
      <Radio.Group>
        <Row>
          <Col span={8}>
            <Radio value="01" style={{ lineHeight: '32px' }}>
              Até 5 checkouts
            </Radio>
          </Col>
          <Col span={8}>
            <Radio value="02" style={{ lineHeight: '32px' }}>
              De 6-15 checkouts
            </Radio>
          </Col>
          <Col span={8}>
            <Radio value="03" style={{ lineHeight: '32px' }}>
              De 15-19 checkouts
            </Radio>
          </Col>
          <Col span={8}>
            <Radio value="04" style={{ lineHeight: '32px' }}>
              Food Service
            </Radio>
          </Col>
        </Row>
      </Radio.Group>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'sales_channel',
            label: 'Canal de vendas',
            children: <Input  style={{ maxWidth: '300px'}}/>, //anchor: refatorar
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <h2 className='form-subtitle' style={{ marginTop: '1.7rem' }}>Logística</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['delivery', 'receives_on_saturday'],
            valuePropName: 'checked',
            children: <Checkbox value={true} style={{ lineHeight: '32px' }}>
                Recebe aos Sábados?
              </Checkbox>
          },
          {
            name: ['delivery', 'uses_scheduling'],
            valuePropName: 'checked',
            children: <Checkbox value={true} style={{ lineHeight: '32px' }}>
                Cliente trabalha com agendamento?
              </Checkbox>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['delivery', 'accepts_pbr_pallets'],
            label: 'Entrega paletizada',
            valuePropName: 'checked',
            style: { width: 'calc(15%)' },
            children:  
              <Checkbox style={{ lineHeight: '32px' }}>
                PDR
              </Checkbox>
          },
          {
            name: ['delivery', 'accepts_disposable_pallets'],
            valuePropName: 'checked',
            label: ' ',
            style: { width: 'calc(15%)', marginRight: '50px' },
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children:  
              <Checkbox style={{ lineHeight: '32px' }}>
                Descartável
              </Checkbox>
          },
          {
            name: ['delivery', 'restriction_cart'],
            label: 'Restrição a algum tipo de veículo',
            valuePropName: 'checked',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            style: { width: 'calc(10%)' },
            children:  
              <Checkbox  style={{ lineHeight: '32px' }}>
                Carreta
              </Checkbox>
          },
          {
            name: ['delivery', 'restriction_truck'],
            label: ' ',
            valuePropName: 'checked',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            style: { width: 'calc(10%)' },
            children:  
              <Checkbox  style={{ lineHeight: '32px' }}>
                Truck
              </Checkbox>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-four'
            // style={item.style}
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['delivery', 'max_vehicles_by_days'],
            label: 'Qtd. máxima de veículos P/ dia (max: 999)',
            children: <InputNumber max={999}/>,
            style: { width: 'calc(20%)' },
          },
          // {
          //   name: ['delivery', 'zone'],
          //   label: 'Zona',
          //   children: <Input />
          // },
          // {
          //   name: ['delivery', 'sector'],
          //   label: 'Setor',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input type="number"/>
          // },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
  ]
}

export function formOptionFourRation({ belongsNetwork, setBelongsNetwork, ufs }: formOptionPropsFour): FormOptionsProps[] {
  return [ 
    {
      children: <h2 className='form-subtitle'>Dados da rede</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'belongNetwork',
            // valuePropName: 'checked',
            children: <Checkbox defaultChecked={belongsNetwork} onChange={() => setBelongsNetwork(!belongsNetwork)}>
              Pertence alguma rede?
            </Checkbox>
          },
          belongsNetwork && {
            name: 'network_name',
            label: 'Nome da rede',
            rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
        ].map((item) => {
          if (item) {
            return (
              <Form.Item 
                {...item} 
                key={item.name}
                className='form-inline-responsive'
              >
                {item.children}
              </Form.Item>
            )
          }
          return null
        })}
      </Form.Item>
    }, 
    {
      children: <Form.Item>
        {[
          {
            name: ['delivery', 'public_place'],
            label: 'Endereço',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
          {
            name: ['delivery', 'deivery_address_number'],
            label: 'N°',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input type="Number"/>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three-left'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          // {
          //   name: 'delivery_address_complement',
          //   label: 'Complemento',
          //   children: <Input />
          // },
          {
            name: ['delivery', 'neighborhood'],
            label: 'Bairro',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
          {
            name: ['delivery', 'city'],
            label: 'Cidade',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <Input />
          },
          {
            name: ['delivery', 'cep'],
            label: 'CEP',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <InputNumber controls={false} />
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['delivery', 'uf'],
            label: 'UF',
            children:  <Select /*value={customerInitial['size']}*/> 
              {ufs && ufs.geonames.map(uf => (
                <Option value={uf.name} key={uf.name}>{uf.name}</Option>
              ))}
            </Select>
          },
          {
            name: ['delivery', 'phone'],
            label: 'Telefone',
            // rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
            children: <MaskInput mask="(00) 0 0000-0000" style={{ width: '100%', maxWidth: '25rem'}}/>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-two-right'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['delivery', 'vehicle_restrict'],
            label: 'Restrição a algum tipo de veículo',
            // valuePropName: 'checked',
            children: <Checkbox.Group>
              <Row>
                <Col>
                  <Checkbox value='carreta' style={{ lineHeight: '32px' }}>
                    Carreta
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox value='truck' style={{ lineHeight: '32px' }}>
                    Truck
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          },
        ].map((item, index) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-two-left'
            style={{ width: `${!index ? 'calc(70% - 25px)' : 'calc(30% - 10px)'}` }}
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: ['delivery', 'max_vehicles_by_days'],
            label: 'Qtd. máxima de veículos P/ dia',
            children: <Input type="Number" />
          },
          // {
          //   name: ['delivery', 'zone'],
          //   label: 'Zona',
          //   children: <Input />
          // },
          // {
          //   name: ['delivery', 'sector'],
          //   label: 'Setor',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input type="number"/>
          // },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={typeof item.name === 'object' ? item.name[1] : item.name}
            className='form-inline-responsive-three'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
  ]
}

export function FormOptionFive(): FormOptionsProps[] {
  // const onChangeCheckboxCreditPayment = (list: CheckboxValueType[]) => {
  // } 

  return [
    {
      children: <h2 className='form-subtitle' style={{ marginTop: '1.7rem' }}>Crédito e cobrança</h2>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'credit_suggestion',
            label: 'Sugestão de limite de crédito R$',
            children: <Input type="number"/>,
          },
          {
            name: 'credit_payment',
            label: 'Forma de pagamento',
            // valuePropName: 'checked',
            children:  
              <Radio.Group>
                {/* <Row>
                  <Col span={10}> */}
                    <Radio value="PREPAYMENT" style={{ lineHeight: '32px' }}>
                      Antecipado
                    </Radio>
                  {/* </Col>
                  <Col span={12}> */}
                    <Radio value="BILLET" style={{ lineHeight: '32px' }}>
                      Boleto
                    </Radio>
                  {/* </Col>
                </Row> */}
                    <Radio value="ACCOUNT_CREDIT" style={{ lineHeight: '32px' }}>
                      Crédito em Conta Cartão
                    </Radio>
                    <Radio value="CARD" style={{ lineHeight: '32px' }}>
                      Cartão
                    </Radio>
                    <Radio value="PIX" style={{ lineHeight: '32px' }}>
                      PIX
                    </Radio>
              </Radio.Group>
          },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
        {[
          {
            name: 'purchase_suggestion',
            label: 'Sugestão para demais compras',
            // valuePropName: 'checked',
            children:  
              <Radio.Group>
                <Row>
                  <Col span={12}>
                    <Radio value="7" style={{ lineHeight: '32px' }}>
                      07 dias
                    </Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value="14" style={{ lineHeight: '32px' }}>
                      14 dias
                    </Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value="28" style={{ lineHeight: '32px' }}>
                      28 dias
                    </Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value="56" style={{ lineHeight: '32px' }}>
                      56 dias
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
          },
          // {
          //   name: 'credit_zone',
          //   label: 'Zona',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
          // {
          //   name: 'credit_sector',
          //   label: 'Setor',
          //   rules: [{ required: true, message: 'Por favor, preencha este campo.' }],
          //   children: <Input />,
          // },
        ].map((item) => (
          <Form.Item 
            {...item} 
            key={item.name}
            className='form-inline-responsive'
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
  ]
}

export function FormOptionSix({ customerResponse, customerInitial }: formOptionsPropsSix): FormOptionsProps[] {
  const handleUploadFile = async ({ documentType, file, onSuccess, onError }: uploadFileProps) => {
    let formData = new FormData();
    formData.append("file", file)
    const response = await api({
      method: 'post',
      url: `/customers/${customerResponse._id}/documents/${documentType}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (response.status === 201) {
      onSuccess()
    } else {
      onError()
    }

    return "finished"
  }

  const getFileByDocumentType = (documentType: string): DocumentProps | undefined => {
    let document = customerInitial?.documents?.filter(document => 
      document.props.document_type === documentType  
    )[0]

    return document
  }

  return [
    {
      children: <h2 className='form-subtitle'>Anexar documentos</h2>
    },
    {
      children: <Form.Item>
         {[
          {
            children: 
              <UploadComponent 
                title='Termo para assinatura cliente:'
                handleSubmit={({ 
                  file, 
                  onSuccess, 
                  onError 
                }) => handleUploadFile({ documentType: 'CUSTOMER_TERM', file, onSuccess, onError})}
                dataFile={getFileByDocumentType('CUSTOMER_TERM')}
              />
          },
          {
            children:
              <UploadComponent 
                title='Contrato Social (Cliente de grande porte):'
                description='Por favor, insira o contrato social do cliente'
                dataFile={getFileByDocumentType('SOCIAL_CONTRACT')}
                handleSubmit={({ 
                  file,
                  onSuccess, 
                  onError 
                }) => handleUploadFile({ documentType: 'SOCIAL_CONTRACT', file, onSuccess, onError})}
              />
          },
        ].map((item, index) => (
          <Form.Item 
            {...item} 
            key={index}
            className="form-inline-responsive"
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
         {[
          {
            children: 
              <UploadComponent 
                title='Documentos pessoais (habilitação ou identidade do sócio):'
                dataFile={getFileByDocumentType('RG')}
                handleSubmit={({ 
                  file, 
                  onSuccess, 
                  onError 
                }) => handleUploadFile({ documentType: 'RG', file, onSuccess, onError})}
              />
          },
          {
            children:
              <UploadComponent 
                title='Comprovante de residência - Para PF do sócio:'
                dataFile={getFileByDocumentType('CNH')}
                handleSubmit={({ 
                  file, 
                  onSuccess, 
                  onError 
                }) => handleUploadFile({ documentType: 'CNH', file, onSuccess, onError})}
              />
          },
        ].map((item, index) => (
          <Form.Item 
            {...item} 
            key={index}
            className="form-inline-responsive"
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    },
    {
      children: <Form.Item>
         {[
          {
            children: 
              <UploadComponent 
                title='Cartão CNPJ:'
                description='Por favor, insira neste campo o cartão CNPJ'
                dataFile={getFileByDocumentType('CNPJ_CARD')}
                handleSubmit={({ 
                  file, 
                  onSuccess, 
                  onError 
                }) => handleUploadFile({ documentType: 'CNPJ_CARD', file, onSuccess, onError})}
              />
          },
          {
            children:
              <UploadComponent 
                title='Cópia do faturamento dos últimos 12 meses:'
                description='Por favor, insira neste campo a cópia do faturamento'
                dataFile={getFileByDocumentType('BILL')}
                handleSubmit={({ 
                  file, 
                  onSuccess, 
                  onError 
                }) => handleUploadFile({ documentType: 'BILL', file, onSuccess, onError})}
              />
          },
        ].map((item, index) => (
          <Form.Item 
            {...item} 
            key={index}
            className="form-inline-responsive"
          >
            {item.children}
          </Form.Item>
        ))}
      </Form.Item>
    }
  ]
}
