import { MaskedInput } from 'antd-mask-input';
import React from 'react'

export const MaskInput = (props: any) => {

  return (
    <MaskedInput 
      {...props}
      mask={props.mask}
    />
  )
}
