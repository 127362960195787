import React from 'react'
import { 
  useRoutes, 
} from 'react-router-dom'
import { AuthProvider } from '../contexts/auth/auth'
import { routes } from './routes'

function RootRouter() {
  const elements = useRoutes(routes)

  return (
    <AuthProvider>
     {elements}
    </AuthProvider>
  )
}

export default RootRouter