import { Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

export const LoaderPage: React.FC<{ children: React.ReactNode | JSX.Element, loader: boolean }> = ({ children, loader }) => {
  return (
    <Spin 
      tip="Carregando..." 
      indicator={
        <Loading3QuartersOutlined
          style={{ fontSize: 24 }} 
          spin 
        />
      }
      spinning={loader}
    >
      {children}
    </Spin>
  )
}

export const LoaderSpin: React.FC = () => {
  return (
    <Spin 
      indicator={
        <LoadingOutlined 
          style={{ fontSize: 32 }} 
          spin 
        />
      }
    />
  )
}