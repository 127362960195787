export const simplifiedFormKeys = [
  'customer_type',
  'branch',
  'company_opening_date',
  'size',
  'cnpj',
  'uf',
  'company_name',
  'cep',
  'phone',
  'fantasy_name',
  'registration_status',
  'registration_status_date',
  'cnae',
  'cnae_code',
  'public_place',
  'number',
  // 'complement',
  'neighborhood',
  'city',
  'email',
  'capital',
  'legal_nature',
  'legal_nature_code',
  'phone_contact',
  'partner',
  'name',
  'cpf',
  'rg',
  'rg_issuer',
  'role',
  'credit_suggestion',
  'credit_payment',
  'purchase_suggestion'
]

export const rationFormKeys = [
  'customer_type',
  'branch',
  'company_opening_date',
  'size',
  'cnpj',
  'uf',
  'company_name',
  'cep',
  'credit_payment',
  'phone',
  'fantasy_name',
  'registration_status',
  'registration_status_date',
  'cnae',
  'cnae_code',
  'public_place',
  'number',
  // 'complement',
  'neighborhood',
  'city',
  'email',
  'capital',
  'legal_nature',
  'legal_nature_code',
  'phone_contact',
  'partner',
  'name',
  'cpf',
  'rg',
  'rg_issuer',
  'role',
  'supplier_name',
  'supplier_public_place',
  'supplier_number',
  'supplier_neighborhood',
  'supplier_city',
  'supplier_uf',
  'supplier_phone',
  'bank_account',
  'delivery'
]

export const chickensFormKeys = [
  'activity_start',
  'accepts_disposable_pallets',
  'accepts_pbr_pallets',
  'belongsNetwork',
  'branch',
  'cep',
  'cnpj_previous_company',
  'checkouts_qtd_enum',
  'credit_payment',
  'credit_suggestion',
  'company_opening_date',
  'customer_type',
  'size',
  'cnpj',
  'uf',
  'company_name',
  'phone',
  'fantasy_name',
  'registration_status',
  'registration_status_date',
  'cnae',
  'cnae_code',
  'public_place',
  'number',
  // 'complement',
  'neighborhood',
  'city',
  'email',
  'capital',
  'legal_nature',
  'legal_nature_code',
  'phone_contact',
  'partner',
  'name',
  'cpf',
  // 'has_cold_camara',
  // 'has_cold_tools',
  'max_vehicles_by_days',
  'member_id',
  'network_name',
  'number',
  'participations_in_previous',
  'previous_company',
  'previous_company_local_reg_number',
  'previous_public_place',
  'purchase_suggestion',
  'rg',
  'rg_issuer',
  'role',
  'sales_channel',
  'size',
  'supplier_name',
  'supplier_public_place',
  'supplier_number',
  'supplier_neighborhood',
  'supplier_city',
  'supplier_uf',
  'supplier_phone',
  'uses_scheduling',
  'delivery',
  'restriction_truck',
  'restriction_cart',
  'receives_on_saturday',
]