import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, UploadProps } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';

import './uploadComponentStyles.scss'
import { uploadFileProps } from './customers/utils/formOptions';
import { DocumentProps } from './customers/CustomersTable';

export interface UploadComponentProps {
  title: string | React.ReactNode
  description?: string | null
  dataFile?: DocumentProps
  handleSubmit: (options: any) => Promise<string>

}

const UploadComponent: React.FC<UploadComponentProps> = ({ title, description = null, handleSubmit, dataFile }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([
    // {
    //   uid: '-1',
    //   name: 'xxx.png',
    //   status: 'done',
    //   url: 'http://www.baidu.com/xxx.png',
    // },
  ]);

  const handleChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);
    // handleSubmit(newFileList)
  };

  const handleDownloadFile = async (file: UploadFile) => {
    const image = await fetch(file.url as string)
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);

    let anchor = document.createElement('a')
    anchor.download = file.name as string

    const href = imageURL
    
    anchor.href = href
    anchor.setAttribute('target', '_blank')
    document.body.appendChild(anchor)

    anchor.click()

    document.body.removeChild(anchor)
    URL.revokeObjectURL(imageURL)
  }

  const props = {
    customRequest: handleSubmit,
    onChange: handleChange,
    onPreview: handleDownloadFile,
    multiple: false,
  };

  useEffect(() => {
    if (dataFile) {
      setFileList([{...dataFile.props, uid: dataFile._id, name: dataFile.props.document_type, url: dataFile.props.public_url }])
    }
  }, [dataFile])

  return (
    <div id="card-upload-component">
      <h4 className='card-title'>{title}</h4>

      {description && <p className="card-description">{description}</p>}

      <span className="card-action-title">Anexo:</span>

      <Upload {...props} fileList={fileList}>
        {fileList.length === 0 && <Button icon={<UploadOutlined />}>Adicionar um arquivo</Button>}
      </Upload>
    </div>
  );
};

export default UploadComponent;