import { CustomersProvider } from 'contexts/customer'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const CustomerRoot = () => {
  return (
    <CustomersProvider>
      <Outlet />
    </CustomersProvider>
  )
}
