import React, { createContext, useEffect, useState } from 'react'
import { DataSourceProps } from 'components/customers/CustomersTable'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import { api } from 'services'
import { FeedbackMessage } from 'utils/FeedbackMessage'

interface CustomerContextProps {
  customers: DataSourceProps[]
  Delete: (customer_id: string) => void
  Update: (customer: DataSourceProps) => void
  Get: () => Promise<any>
  Create: (customer: DataSourceProps) => Promise<any>
  loading: boolean
}

dayjs.extend(customParseFormat)
const formatPtBr = ['DD/MM/YYYY', 'DD/MM/YY']

const customerMock: DataSourceProps[] = [
  {
    customer_type: 'CHICKEN',
    company_name: 'Casa das rações',
    email: 'racoes@gmail.com',
    phone: '83 99999-0000',
    key: Date.now().toString(),
    id: '1669069137566',
    status: true,
    activity_start: dayjs(new Date().toString(), formatPtBr),
    bank_supplier_institutions: "dfgsds",
    bank_supplier_phone: "2342323423",
    bank_supplier_agency: "34",
    bank_supplier_checking_account
    : 
    "234",
    belongsNetwork
    : 
    true,
    branch
    : 
    "ab",
    buy_sugestions
    : 
    ['28'],
    capital
    : 
    123213,
    cep
    : 
    "3233",
    city
    : 
    "gfgf",
    cnae
    : 
    "01156",
    cnae_code
    : 
    "01156",
    cnpj
    : 
    "21321321321",
    company_opening_date
    : 
    dayjs('23/11/2022', formatPtBr),
    complement
    : 
    "dfdsfs",
    cpf
    : 
    "546456456",
    credit_limit_sugestion
    : 
    "23432",
    credit_sector
    : 
    "Agricola",
    credit_zone
    : 
    "21",
    enterprise_address
    : 
    "JP/PB",
    enterprise_cnpj
    : 
    "qweqwe334",
    enterprise_successor
    : 
    true,
    eo
    : 
    "eter",
    fantasy_name
    : 
    "aabb",
    form_of_payment
    : 
    ['a_vista'],
    google_phone
    : 
    "23424234",
    integralized
    : 
    32000,
    is_store
    : 
    ['1'],
    legal_nature
    : 
    "1015 Órgão Público do Poder Executivo Federal",
    legal_nature_code
    : 
    "1015",
    max_vehicles_by_days
    : 
    234,
    name_enterprise_successor
    : 
    "atacadões",
    neighborhood
    : 
    "etert",
    network_name
    : 
    'rede',
    number
    : 
    "234234",
    operation
    : 
    "4",
    palletized_delivery_logistics
    : 
    ['PDR'],
    partner: {
      cep
      : 
      "56465465",
      city
      : 
      "hvbjvj",
      cpf
      : 
      "654.546.545-46",
      name
      : 
      "gjhkgjhg",
      neighborhood
      : 
      "mnmn",
      number
      : 
      "4545",
      rg
      : 
      "56465464465",
      rg_issuer
      : 
      "kjjj",
      role
      : 
      "1",
      public_place
      : 
      "ggtgkg",
      uf
      : 
      "Bahia",
    },
    phone_classification
    : 
    '1',
    phone_client
    : 
    "342354",
    public_place
    : 
    "23434",
    rank
    : 
    "234",
    registration_status
    : 
    "2",
    registration_status_date
    : 
    dayjs(new Date().toString(), formatPtBr),
    registry_capital
    : 
    "234324",
    related_company
    : 
    "23",
    rg
    : 
    "456546",
    sales_channel
    : 
    "2",
    receives_on_saturday
    : 
    true,
    scheduling_logistics
    : 
    false,
    search_count
    : 
    "234",
    size
    : 
    "2",
    uf
    : 
    "Alagoas",
    social_contract: {
      previous_company_local_reg_number: "123",
      previous_company: "Empresa anterior"
    },
    supplier_address
    : 
    "sdf",
    supplier_city
    : 
    "sddf",
    supplier_complement
    : 
    "sdf4",
    supplier_name
    : 
    "fdgfd",
    supplier_neighborhood
    : 
    "dsf",
    supplier_number
    : 
    "345",
    supplier_phone
    : 
    "4334435",
    supplier_uf
    : 
    "Ceará",
    tags
    : 
    "23434",
    total_amount
    : 
    433,
    restriction_truck: true,
    restriction_cart: true,
    vehicle_restriction_logistics
    : 
    ['carreta'],
    wallet: '1'
  }
]

export const CustomersContext = createContext<CustomerContextProps>(null!)

export const CustomersProvider = ({ children }: { children: React.ReactNode }) => {
  const [customers, setCustomers] = useState<DataSourceProps[]>([])
  const [loading, setLoading] = useState(true)
  const [activateFeedback, setActivateFeedback] = useState(false)
  const [messageFeedback, setMessageFeedback] = useState('')
  const [typeFeedback, setTypeFeedback] = useState<'success' | 'warning' | 'error'>('success')

  const Delete = async (customer_id: string) => {
    try {
      const response = await api({
        method: 'delete',
        url: `/customers/${customer_id}`,
      })
      
      if (response.status === 200 || response.status === 201) {
        setCustomers(customers.filter(item => item.id !== customer_id))
        setMessageFeedback('Excluído com sucesso!')
        setTypeFeedback('success')
        setActivateFeedback(true)
      } else {
        setMessageFeedback('Houve um problema ao excluir, tente novamente, mais tarde!')
        setTypeFeedback('error')
        setActivateFeedback(true)
      }
    } catch (error) {
      setMessageFeedback('Houve um problema ao excluir, tente novamente, mais tarde!')
      setTypeFeedback('error')
      setActivateFeedback(true)
    } finally {
      setTimeout(() => setActivateFeedback(false), 5000)
    }
  }

  const Update = async (customer: DataSourceProps) => {
    const response = await api({
      method: 'put',
      url: `/customers/${customer.id}`,
      data: customer
    })


    let customersCopy = [...customers]
    let index = customersCopy.findIndex(item => item.id === customer.id)
    customersCopy.splice(index, 1, customer)
    setCustomers(customersCopy)
    return response
  }

  const Get = async () => {
    // setCustomers([...customers, customer])
    const response = await api({
      method: 'get',
      url: '/customers',
      params: {
        page: 1,
        page_size: 100
      }
    })
    // const response = await http.get('/customers')
    if (response?.status === 200 ) {
      const customersList = Array.from(response.data, 
        (data: { props: DataSourceProps, _id: string})  =>  { 
          return { 
            ...data.props, 
            partner: { 
              ...data?.props?.partner?.props, 
              id: data?.props?.partner?._id
            },
            delivery: {
              ...data?.props?.delivery?.props, 
              id: data?.props?.delivery?._id
            },
            social_contract: {
              ...data?.props?.social_contract?.props, 
              id: data?.props?.social_contract?._id
            },
            id: data._id, key: data._id 
          } 
        })
      setCustomers(customersList)
    }

      // return response
  }

  const Create = async (customer: DataSourceProps) => {
    const response = await api({
      method: 'post',
      url: '/customers',
      data: customer
    })

    return response
  }

  useEffect(() => {
    Get();
    setLoading(false);
    // setCustomers(customerMock)
  }, [])

  return (
    <CustomersContext.Provider value={{ Delete, customers, Update, Get, Create, loading }}>
      {!loading && children}
      <FeedbackMessage activate={activateFeedback} content={messageFeedback} type={typeFeedback} />
    </CustomersContext.Provider>
  )
}
