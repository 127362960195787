import { DataSourceProps } from "components/customers/CustomersTable"
import { api } from "services"
import { domainToASCII } from "url"

export interface UFProps {
  geonames: {adminCodes1: object, name: string }[]
}

export interface CNAEProps {
  descricao: string
  id: string
}

export interface BanksProps {
  code: string
  enabled: boolean
  initials: string
  name: string
  id: string
}

export const getBrazilStates = async () => {
  let ufs: UFProps = await fetch('https://www.geonames.org/childrenJSON?geonameId=3469034', { method: 'GET'})
  .then(response => { return response.json()})
  return ufs
}

export const getCNAEList = async () => {
  let cnae: CNAEProps[] = await fetch('https://servicodados.ibge.gov.br/api/v2/cnae/classes', { method: 'GET' })
  .then(response => { return response.json()})
  return cnae
}

export const getAllBank = async () => {
  let data: BanksProps[] = []
  let response = await api({
    method: 'get',
    url: '/banks'
  })

  if (response.status === 200) {
    data = response.data.map((data: any) => {
      return { 
        ...data.props,
        id: data._id
      }
    })
    return data
  }

  return data
}

export const cleanStrings = (values: any): DataSourceProps => {
  const dictionaryMasked = ['cnpj', 'cpf', 'phone', 'google_phone', 'phone_contact', 'supplier_phone']

  for(let item of Object.keys(values)) {
    if (typeof values[item] === "string" && dictionaryMasked.includes(item) ){
      values[item] = values[item].replace(/[.()' '/-]/g,'')
    } else if ( !!values[item] && typeof values[item] === "object" && !Array.isArray(values[item])) {
      for(let itemOfObj of Object.keys(values[item])) {
        if (typeof values[item][itemOfObj] === "string" && dictionaryMasked.includes(itemOfObj) ){
          values[item][itemOfObj] = values[item][itemOfObj].replace(/[.()' '/-]/g,'')
        }
      }
    }
  }
  return {...values}
}