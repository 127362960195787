import { Avatar, Button } from 'antd'
import { useAuth } from 'hooks/useAuth'
import React from 'react'

import './style.scss'

const photo = 'https://avatars.githubusercontent.com/u/20484607?v=4'

export const User = () => {
  const auth = useAuth()

  return (
    <div id='user-profile-component'>
      <Avatar className='avatar' src={auth.user?.picture}>
        {auth.user?.name.substring(0, 1)}
      </Avatar>
      <Button 
        className='btnSignout' 
        type='text' 
        onClick={auth.Logout}
      >
        Sair
      </Button>
    </div>
  )
}
