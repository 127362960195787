import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css'
import { ConfigProvider } from 'antd';
import moment from 'moment';
import ptBR from 'antd/es/locale/pt_BR';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const locale = ptBR
moment.locale('pt-br')

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>
);
