import React, { useContext } from 'react'
import { TableComponent } from 'components/Table'
import { Button, Space } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { ColumnsType } from 'antd/lib/table'
import { useState } from 'react'
import { useEffect } from 'react'
import { api } from 'services'
import { FeedbackMessage, FeedbackMessageProps } from 'utils/FeedbackMessage'
import { SellersContext } from 'contexts/sellers'
import { LoaderPage } from 'components/Loader'

export interface DataSourceProps {
  key?: string
  name: string
  email: string
  id?: string
  is_admin: boolean
}

interface SellersTableProps {
  data?: DataSourceProps[] //opcional só para testes
}



export const SellersTable: React.FC<SellersTableProps> = () => {
  const sellerContext = useContext(SellersContext)
  const [sellersData, setSellersData] = useState<DataSourceProps[]>([])
  const [feedback, setFeedback] = useState<FeedbackMessageProps>({ activate: false, content: '', type: 'success'})
  const [loading, setLoading] = useState(true)
  
  const handleSellerDelete = async (seller: DataSourceProps) => {
    try {
      setLoading(true)
      const response = await api({
        method: 'delete',
        url: `/members/${seller.id}`
      })

      if (response.status === 200) {
        let index = sellersData.indexOf(seller)
        const newSellersData = [...sellersData]
  
        newSellersData.splice(index, 1)
        
        setSellersData(newSellersData)
        setFeedback({activate: true, content: 'Vendedor deletado com sucesso!',  type: 'success'})
      } else {
        setFeedback({activate: true, content: 'Houve um problema durante a exclusão. Tente novamente!',  type: 'error'})
      }

    } catch (error) {
      setFeedback({activate: true, content: 'Houve um problema durante a exclusão. Tente novamente!',  type: 'error'})
    } finally {
      setTimeout(() => setFeedback({activate: false, content: '',  type: 'success'}), 1000)
      setLoading(false)
    }
    
  }

  const columns: ColumnsType<DataSourceProps> = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Nome'
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'E-mail'
    },
    {
      key: 'is_admin',
      title: 'Administrador?',
      render: (_, record) => (
        <Space>
          <Button 
            type="text"
            style={{ cursor: 'default'}}
          > 
            {record.is_admin ? 
              <CheckCircleTwoTone twoToneColor='#2fb42f'/> :
              <CloseCircleTwoTone twoToneColor='#cf3d33' />
            }
          </Button>
        </Space>
      )
    },
    {
      key: 'action',
      title: 'Ações',
      render: (_, record) => (
        <Space>
          <Link to={`/vendedores/${record.id}`}> <EyeOutlined style={{ fontSize: '20px'}}/> </Link>
          <Button 
            onClick={() => handleSellerDelete(record)}
            type="text"
          > 
            <DeleteOutlined style={{ fontSize: '18px'}}/>
          </Button>
        </Space>
      )
    }
  ]

  useEffect(() => {
    sellerContext.Get().finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSellersData(sellerContext.sellers)
  }, [sellerContext.sellers])

  return (
    <>
      <LoaderPage loader={loading}>
        <TableComponent 
          columns={columns}
          dataSource={sellersData}
        />
      </LoaderPage>
      <FeedbackMessage 
        activate={feedback.activate}
        content={feedback.content}
        type={feedback.type}
      />
    </>
  )
}
