import { Forms, StepsProps } from 'components/Forms'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'
// import { api } from 'services'
import { FeedbackMessage, FeedbackMessageProps } from 'utils/FeedbackMessage'
import { useNavigate, useParams } from 'react-router-dom'
import { DataSourceProps } from './CustomersTable'
import { CustomersContext } from 'contexts/customer'
import { BanksProps, cleanStrings, CNAEProps, getAllBank, getBrazilStates, getCNAEList, UFProps } from 'utils'
import { FormOption, FormOptionFive, formOptionFour, formOptionFourRation, FormOptionSix, formOptionThree, formOptionTwo, formOptionTwoRation } from './utils/formOptions'
import { api } from 'services'
import dayjs from 'dayjs'


export const CustomerForms = ({ type }: { type: 'create' | 'edit' }) => {
  const [initialValues, setInitialValues] = useState<DataSourceProps>({  company_name: '', email: '', id: '', status: false, key: Date.now().toString(), customer_type: 'CHICKEN' })
  // const [checkedIsAdmin, setCheckedIsAdmin] = useState(true)
  const [customerInitial, setCustomerInitial] = useState<DataSourceProps>({  company_name: '', email: '', id: '', key: Date.now().toString(), customer_type: 'CHICKEN' })
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [activateNotify, setActivateNotify] = useState(false)
  const [feedback, setFeedback] = useState<FeedbackMessageProps>({ activate: activateNotify, content: '', type: 'success', setActivate: setActivateNotify })
  const CustomerContext = useContext(CustomersContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [formType, setFormType] = useState<"CHICKEN" | "RATIONS" | "SIMPLIFIED">("CHICKEN")
  const [formData, setFormData] = useState<DataSourceProps>(null!)
  
  const [ufs, setUfs] = useState<UFProps>(null!)
  const [cnae, setCnae] = useState<CNAEProps[]>([])
  const [belongsNetwork, setBelongsNetwork] = useState(false)
  const [enterpriseSuccessor, setEnterPriseSuccessor ] = useState(false)
  const [banks, setBanks] = useState<BanksProps[]>(null!)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [loadingCustomerSubmit, setLoadingCustomerSubmit] = useState(true)
  const [loadingSendToProtheus, setLoadingSendToProtheus] = useState(false)
  const [cnpj, setCNPJ] = useState('')
  const [submitConfirm, setSubmitConfirm] = useState<boolean | null>(null)
  const [customerResponse, setCustomerResponse] = useState<DataSourceProps & { _id: string}>(null!)

  const chickenSteps = useMemo(() :StepsProps[] => {
    return [
      {
        title: 'Dados',
        formOptions: FormOption({ cnae, customerInitial, setFormType, ufs, cnpj, setCNPJ, form, loading: loadingSearch, setLoading: setLoadingSearch, setFeedback })
      },
      {
        title: 'Info',
        formOptions: formOptionTwo({ ufs, enterpriseSuccessor, setEnterPriseSuccessor })
      },
      {
        title: 'Referências',
        formOptions: formOptionThree({ ufs, banks })
      },
      {
        title: 'Rede',
        formOptions: formOptionFour({ belongsNetwork, setBelongsNetwork })
      },
      {
        title: 'Social',
        formOptions: FormOptionFive()
      },
      {
        title: 'Documentos',
        formOptions: FormOptionSix({ customerResponse, customerInitial })
      }
    ]
  }, [cnae, customerInitial, ufs, cnpj, form, loadingSearch, banks, belongsNetwork, enterpriseSuccessor, customerResponse])

  const rationSteps = useMemo((): StepsProps[] => {
    return [
      {
        title: 'Dados',
        formOptions: FormOption({ cnae, customerInitial, setFormType, ufs, cnpj, setCNPJ, form, loading: loadingSearch, setLoading: setLoadingSearch, setFeedback })
      },
      {
        title: 'Info',
        formOptions: formOptionTwoRation({ ufs, enterpriseSuccessor, setEnterPriseSuccessor })
      },
      {
        title: 'Referências',
        formOptions: formOptionThree({ ufs, banks })
      },
      {
        title: 'Rede',
        formOptions: formOptionFourRation({ belongsNetwork, setBelongsNetwork, ufs })
      },
      {
        title: 'Documentos',
        formOptions: FormOptionSix({ customerResponse, customerInitial })
      }
    ]
  }, [cnae, customerInitial, ufs, cnpj, form, loadingSearch, enterpriseSuccessor, banks, belongsNetwork, customerResponse])

  const simplifiedSteps = useMemo((): StepsProps[] => {
    return [
      {
        title: 'Dados',
        formOptions: FormOption({ cnae, customerInitial, setFormType, ufs, cnpj, setCNPJ, form, loading: loadingSearch, setLoading: setLoadingSearch, setFeedback })
      },
      {
        title: 'Info',
        formOptions: formOptionTwo({ ufs, enterpriseSuccessor, setEnterPriseSuccessor })
      },
      {
        title: 'Social',
        formOptions: FormOptionFive()
      },
      {
        title: 'Documentos',
        formOptions: FormOptionSix({ customerResponse, customerInitial })
      }
    ]
  }, [cnae, customerInitial, ufs, cnpj, form, loadingSearch, enterpriseSuccessor, customerResponse])

  const steps = useMemo((): StepsProps[] => {
    switch (formType) {
      case 'CHICKEN':
        return [...chickenSteps];
      case 'RATIONS':
        return [...rationSteps];
      default:
        return [...simplifiedSteps];
    }
  }, [formType, chickenSteps, rationSteps, simplifiedSteps])

  const mock: DataSourceProps = {
    public_place
  : 
  "shgfs",
  branch
  : 
  "hggg",
  capital
  : 
  45446,
  cep
  : 
  "546546546",
  city
  : 
  "gjhbhh",
  cnae
  : 
  "01113",
  cnae_code
  : 
  "01113",
  cnpj
  : 
  "44.654.646/4654-65",
  company_name
  : 
  "223432",
  company_opening_date
  : 
  dayjs("2022-12-06T12:23:41.952Z"),
  complement
  : 
  "nklhll57",
  credit_payment
  : 
  "PIX",
  credit_suggestion
  : 
  4654654,
  customer_type
  : 
  "SIMPLIFIED",
  email
  : 
  "bkhlk@gfchg.com",
  fantasy_name
  : 
  "jkhkljhjklj",
  google_phone
  : 
  "4654 564 5464",
  key
  : 
  "1671193537760",
  legal_nature
  : 
  "1023 Órgão Público do Poder Executivo Estadual ou do Distrito Federal",
  legal_nature_code
  : 
  "1023",
  neighborhood
  : 
  "jjjj",
  number
  : 
  "54654",
  partner: {
    cep
    : 
    "56465465",
    city
    : 
    "hvbjvj",
    cpf
    : 
    "654.546.545-46",
    name
    : 
    "gjhkgjhg",
    neighborhood
    : 
    "mnmn",
    number
    : 
    "4545",
    rg
    : 
    "56465464465",
    rg_issuer
    : 
    "kjjj",
    role
    : 
    "1",
    public_place
    : 
    "ggtgkg",
    uf
    : 
    "Bahia",
  },
  phone
  : 
  "(64) 6 5465-4654",
  phone_classification
  : 
  "1",
  phone_contact
  : 
  "(65) 4 6546-5465",
  purchase_suggestion
  : 
  "14",
  rank
  : 
  "hgjhgjhgjhkghg",
  registration_status
  : 
  "7",
  registration_status_date: dayjs("2021-11-02T12:23:41.952Z"),
  related_company
  : 
  "hohjhjh",
  search_count
  : 
  "4545",
  size
  : 
  "2",
  uf
  : 
  "Amapá",
  tags
  : 
  "gghgjhgj",
  total_amount
  : 
  44,
  wallet
  : 
  "1",
  }
  const onSubmit = async (values: any) => {
    const data: DataSourceProps = cleanStrings({ ...formData, ...values})
    
    try {
      // setLoading(true)

      const {
        partner,
        delivery,
        social_contract: socialContracts
      } = data 

      let response = null
      
      switch (type) {
        case 'create':
          if (!customerResponse?._id) { 
            response = await CustomerContext.Create({...data, key: Date.now().toString()})
          } else {
            response = await CustomerContext.Update({...data, id: String(customerResponse._id), key: Date.now().toString()})
          }
          break;
          default:
            response = await CustomerContext.Update({...data, id: String(id), key: Date.now().toString()})
            break;
      }


      if (response.status === 201 || response.status === 200) {
        setSubmitConfirm(true)
        setCustomerResponse(response.data)

      } else {
        setFeedback({activate: true, content: `Houve um erro durante ${type === 'create' ? 'o cadastro' : 'a edição'}. Erro: ${response?.data?.message} (cliente)!`, type: 'error'})
        setSubmitConfirm(false)
      } 

      if (partner != null) {
        let partnerSanitized = cleanStrings(partner)
        const partnerResponse = await api({
          method: 'post',
          url: `/customers/${response.data._id}/partners`,
          data: [{...partnerSanitized, customer_id: response.data._id}]
        })

        if (partnerResponse.status === 409) {
          // console.log(partnerResponse)
          let partnerId = type === 'edit' ? customerInitial?.partner?.id : null

          if (partnerId) {
            await api({
              method: 'put',
              url: `/partners/${partnerId}`,
              data: {...partnerSanitized, customer_id: response.data._id}
            })
          } else {
            setFeedback({activate: true, content: `Houve um erro durante ${type === 'create' ? 'o cadastro' : 'a edição'}. Erro: ${partnerResponse?.data?.message} (sócio)!`, type: 'error'})
          }
        }
      }
      

      if (formType !== 'SIMPLIFIED') {

        if (delivery != null) {
          let deliveriesSanitized = cleanStrings(delivery)
        
          const responseDelivery = await api({
            method: 'post',
            url: `/customers/${response.data._id}/deliveries`,
            data: {...deliveriesSanitized}
          })

        
          if (responseDelivery.status !== 201) {
            await api({
              method: 'put',
              url: `/deliveries/${customerInitial?.delivery?.id}`,
              data: {...deliveriesSanitized, customer_id: response.data._id}
            })
          }
        }


        if (socialContracts != null) {
          let socialContractsSanitized = cleanStrings(socialContracts)

          const responseSocialContracts = await api({
            method: 'post',
            url: `/customers/${response.data._id}/social-contracts`,
            data: {...socialContractsSanitized}
          })
          
          if (responseSocialContracts.status !== 201) {
            // console.log(customerInitial)

            await api({
              method: 'put',
              url: `/social-contracts/${customerInitial?.social_contract?.id}`,
              data: {...socialContractsSanitized, customer_id: response.data._id}
            })
          }
        }

      }
          // await CustomerContext.Create({...data, key: Date.now().toString()})
      // setFeedback({activate: true, content: `Vendedor ${type === 'create' ? 'cadastrado' : 'editado'} com sucesso!`, type: 'success'})

      // const response = await api({
      //   method: 'post',
      //   url: '/members',
      //   data: values,
      // })
      
    } catch (error) {
      setSubmitConfirm(false)
      console.log('response: ', error)

    } finally {
      setLoadingCustomerSubmit(false)
      // setLoading(false)
      // setTimeout(() => setFeedback({activate: false, content: '', type: 'success'}), 5000)
      // setActivated(false)
    }
    
  }

  const onSubmitToProtheus = async () => {
    try {
      setLoadingSendToProtheus(true)

      const response = await api({
        method: 'post',
        url: `/customers/${customerResponse._id}/protheus`,
      })
  
      if (response.status === 200 || response.status === 201) {
        setFeedback({activate: true, content: `Dados do cliente enviados para o Protheus!`, type: 'success'})
      } else {
        setFeedback({activate: true, content: `Houve uma falha no envio para o Protheus, tente novamente mais tarde, se persistir, entre em contato com o suporte!`, type: 'error'})
      }

      setLoadingSendToProtheus(false)

    } catch (error) {
      setFeedback({activate: true, content: `Houve uma falha no envio para o Protheus, tente novamente mais tarde, se persistir, entre em contato com o suporte!`, type: 'error'})
      setLoadingSendToProtheus(false)
    }
    
  }

  const onUpload = async () => {
    // setLoading(true)
    
    if (submitConfirm) {
      setFeedback({activate: true, content: `Cliente ${type === 'create' ? 'cadastrado' : 'editado'} com sucesso!`, type: 'success'})
      setTimeout(() => navigate('/clientes'), 200)
    } else {
      setFeedback({activate: true, content: `Houve um erro durante ${type === 'create' ? 'o cadastro' : 'a edição'}. Tente novamente!`, type: 'error'})
    }
    // navigate('/clientes')
  }

  const onNextData = (values: any) => {
    setFormData({ ...formData, ...values })
  }

  useEffect(() => {
    if (type === 'edit') {
      setLoading(true)
    }
  }, [type])
  

  useEffect(() => {
    if (id) {
      let customerInitialTemp = CustomerContext.customers.filter(customer => String(customer.id) === String(id))[0]
      setCustomerInitial(customerInitialTemp)
      setInitialValues(customerInitialTemp)
      setBelongsNetwork(Boolean(customerInitialTemp?.network_name));
      setCNPJ(customerInitialTemp?.cnpj as string)
      setEnterPriseSuccessor(customerInitialTemp?.social_contract?.is_sucessor as boolean)
      setFormType(customerInitialTemp?.customer_type);

      form.setFieldsValue(customerInitialTemp)
      setFormData({...customerInitialTemp})
    }
    setTimeout(() => setLoading(false), 500)

  }, [form, id, CustomerContext.customers])

  useEffect(() => {
    getBrazilStates().then(response => setUfs(response))
    getCNAEList().then(response => setCnae(response))
    getAllBank().then(response => setBanks(response))
  }, [])

  return (
    <div>
      <Forms 
        steps={steps}
        buttonTitle={type === 'create' ? 'Criar' : 'Editar'}
        initialValues={initialValues}
        handleSubmit={onUpload}
        handleSubmitToProtheus={onSubmitToProtheus}
        form={form}
        loading={loading}
        loadingCustomerSubmitButton={loadingCustomerSubmit}
        handleNextData={onNextData}
        formData={formData}
        backToPage='/clientes'
        handleSaveAndSubmit={onSubmit}
        loadingSendToProtheus={loadingSendToProtheus}
      />
      <FeedbackMessage 
          activate={feedback.activate}
          content={feedback.content}
          type={feedback.type}
          setActivate={(value) => setFeedback({activate: value, content: '', type: 'success'})}
        />
    </div>
  )
}
